<template>
	<div class="row" :key="`questiondetail_${question.question_data.id}`">
    <div class="col-md-11">
      <div class="gap-10 peers">
        <div class="peer" v-if="question.question_data.priority">
          <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15" :class="priorityColor(question.question_data.priority.toLowerCase())">
            {{ question.question_data.priority }}
          </span>
        </div>
        <div class="peer">
          <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-blue-50 c-blue-500">
            {{ question.question_data.department }}
          </span>
        </div>
        <div class="peer" v-if="question.response_data.status">
          <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-orange-50 c-orange-500">
            {{ toCamel(question.response_data.status) }}
          </span>
        </div>
      </div>
      <h6 class="c-grey-900 mT-10">
        <h5>{{ index }}. {{ question.question_data.body }}</h5>
      </h6>
    </div>
    <div class="col-md-1">
      <button href="javascript:void(0)" class="btn" data-bs-toggle="modal" :data-bs-target="`#info_for_${section.id}_${question.question_data.id}`" style="float: right">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      </button>
    </div>
    <QuestionInfo :question="question" :section="section" :key="`key_for_info_for_${section.id}_${question.question_data.id}`"/>
  </div>
</template>
<script>
  import { ref, onMounted, reactive } from "vue"
  import QuestionInfo from '@/components/common/QuestionInfo'

  export default {
    props: ['question', 'index', 'section'],
    components: { QuestionInfo },
    setup(props) {
    	const toCamel = (str) => {
        return str.replace('_', ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      const priorityColor = (priority) => {
        switch (priority) {
          case "critical":
            return 'bgc-red-50 c-red-500'
          case "high":
            return 'bgc-purple-50 c-purple-500'
          case "medium":
            return 'bgc-green-50 c-green-500'
          case "low":
            return 'bgc-blue-50 c-blue-500'
          default:
            return 'bgc-blue-50 c-blue-500'
        }
      }

      return { toCamel, priorityColor }
    }
  }
</script>