<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">{{ company.id ? 'Edit Company' : 'New Company' }}</h4>
          </div>
          <div class="peer">
            <a href="/companies" class="btn cur-p btn-info btn-color"> <span class="icon-holder"><i class="c-white ti-angle-left"></i></span> Back </a>&nbsp;
            <button type="button" class="btn cur-p btn-primary btn-color" @click="submitCompany">Save</button>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Name</label> 
            <input type="text" class="form-control" placeholder="Name" v-model="company.name">
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Website</label> 
            <input type="text" class="form-control" placeholder="Website" v-model="company.website">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Logo</label><br />
            <input class="form-control form-control-sm" id="formFileSm" ref="inputFile" type="file" @change="uploadFile">
          </div>
          <div class="mb-3 col-md-6" style="margin-top: 30px">
            <label class="boxed-check">
              Send Invitation &nbsp;&nbsp;
              <input class="boxed-check-input" type="checkbox" key="sendInvitation" v-model="addUsers.send_invitation">
            </label>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-12">
            <label class="form-label">Description</label><br />
            <textarea placeholder="Company description here...." v-model="company.description"></textarea>
          </div>
        </div>
        <div class="mB-20" v-if="company.id == null">
          <label class="form-label">Select Assessments</label><br />
          <div class="gap-10 peers">
            <div class="peer" v-for="standardAssessment in standardAssessments">
              <div class="form-check boxed-check">
                <label class="form-label form-check-label">
                  <input 
                    class="form-check-input boxed-check-input" 
                    type="checkbox"
                    :id="`standardAssessment_${standardAssessment.id}`" 
                    :name="`standardAssessment_${standardAssessment.id}`" 
                    :key="`standardAssessment_${standardAssessment.id}`" 
                    v-model="assessmentIds" :value="standardAssessment.id"
                  > {{ standardAssessment.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="masonry-item col-md-6 mB-20">
            <div class="bgc-white p-20 bd">
              <h6 class="c-grey-900">Add Vendors</h6>
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr v-for="(vendor, idx) in company.company_vendors" :key="`existing_vendor_${idx}`" v-if="company.company_vendors">
                    <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + 1 }}</th>
                    <td><input type="text" class="form-control" placeholder="Name" v-model="vendor.name" disabled></td>
                    <td><input type="email" class="form-control" placeholder="Email" v-model="vendor.email" disabled></td>
                  </tr>
                  <tr v-for="(vendor, idx) in addUsers.vendors" :key="idx" :user="vendor">
                    <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + (company.company_vendors ? company.company_vendors.length : 0) + 1 }}</th>
                    <td><input type="text" class="form-control" placeholder="Name" v-model="vendor.name"></td>
                    <td><input type="email" class="form-control" placeholder="Email" v-model="vendor.email"></td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <button type="button" class="btn btn-secondary btn-color" @click="addMoreUsers(true)"><i class="ti ti-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="masonry-item col-md-6 mB-20">
            <div class="bgc-white p-20 bd">
              <h6 class="c-grey-900">Add Evaluators</h6>
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr v-for="(evaluator, idx) in company.company_evaluators" :key="`existing_evaluator_${idx}`" v-if="company.company_evaluators">
                    <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + 1 }}</th>
                    <td><input type="text" class="form-control" placeholder="Name" v-model="evaluator.name" disabled></td>
                    <td><input type="email" class="form-control" placeholder="Email" v-model="evaluator.email" disabled></td>
                  </tr>
                  <tr v-for="(evaluator, idx) in addUsers.evaluators" :key="idx" :user="evaluator">
                    <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + (company.company_evaluators ? company.company_evaluators.length : 0) + 1 }}</th>
                    <td><input type="text" class="form-control" placeholder="Name" v-model="evaluator.name"></td>
                    <td><input type="email" class="form-control" placeholder="Email" v-model="evaluator.email"></td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <button type="button" class="btn btn-secondary btn-color" @click="addMoreUsers(false)"><i class="ti ti-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
  textarea {
    border:1px solid #999999;
    width:100%;
    margin:5px 0;
    padding:1%;
  }
</style>

<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useCompanies from '@/composables/admin/companies.composable'
  import AddUser from '@/components/companies/AddUser'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { AddUser },
    setup() {
      const route = useRoute()
      const { getCompany, company, createCompany, updateCompany, getStandardAssessments, standardAssessments } = useCompanies()
      const assessmentIds = ref([])
      const addUsers = ref({
        vendors: [],
        evaluators: [],
        send_invitation: true
      })

      onMounted(()=> {
        if (route.params.id) {
          getCompany(route.params.id)
        }
        getStandardAssessments()
      })

      const addMoreUsers = (addVendor) => {
        if (addVendor == true) {
          addUsers.value.vendors.push({ name: '', email: '' })
        } else {
          addUsers.value.evaluators.push({ name: '', email: '' })
        }
      }

      const submitCompany = () => {
        company.value.id ? updateCompany(company.value, addUsers.value, assessmentIds.value) : createCompany(company.value, addUsers.value, assessmentIds.value)
      }

      return { company, addUsers, submitCompany, standardAssessments, assessmentIds, addMoreUsers }
    }
  }
</script>