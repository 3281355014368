<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Assessments For Roles</h4>
          </div>
        </div>
        <AssessmentList :assessments="assessments" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue"
  import useReports from '@/composables/admin/reports.composable'
  import AssessmentList from '@/components/common/DepartmentAssessment'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { AssessmentList },
    setup() {
      const { getAssessments, assessments } = useReports()

      onMounted(()=> {
        getAssessments()
      })

      return { assessments }
    }
  }
</script>