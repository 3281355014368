import axios from 'axios'
import store from "@/store"
import qs from 'qs'

const API_URL = process.env.VUE_APP_API_URL

const secured = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }
})

const plain = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json',
  }
})

secured.interceptors.request.use(config => {
  const method = config.method.toUpperCase()
  store.commit("loading/setLoading", true)
  config.headers = {
    ...config.headers,
    'Authorization': store.getters.authorizationToken
  }

  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    })
  }

  return config
})

secured.interceptors.response.use(
  (res) => {
    store.commit("loading/setLoading", false)
    return res
  },
  (err) => {
    store.commit("loading/setLoading", false)
    return err
  }
);

export { secured, plain }
