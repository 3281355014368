import { notify } from "@kyvg/vue3-notification"

export default function useNotifier() {
  const notifySuccess = (title, message) => {
    notify({ 
      type: 'success', 
      title: title, 
      text: message 
    })
  }

  const notifyWarn = (title, message) => {
    notify({ 
      type: 'warn', 
      title: title, 
      text: message 
    })
  }

  const notifyError = (title, message) => {
    notify({ 
      type: 'error', 
      title: title, 
      text: message 
    })
  }

  return { notifySuccess, notifyWarn, notifyError }
}