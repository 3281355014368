<template>
  <ul class="nav-left">
    <li>
      <a id='sidebar-toggle' class="sidebar-toggle" href="javascript:void(0);">
        <i class="ti-menu"></i>
      </a>
    </li>
  </ul>
  <ul class="nav-left gap-10 mT-10 mL-10" v-if="['VendorAssessmentsControl', 'EvaluatorAssessmentsControl'].includes(route.name)">
    <li style="margin-top: 8px">
      <div class="peer"><span class="fsz-sm c-grey-900">Filter By</span></div>
    </li>
    <li>
      <select id="selectDepartment" class="form-control" v-model="store.getters.filters.department">
        <option selected="selected" value="">Select Department...</option>
        <option :value="department" v-for="department in store.getters.filtersData.departments">{{ department }}</option>
      </select>
    </li>
    <li>
      <button type="button" class="btn btn-primary btn-color" @click="filterData">Filter</button>
    </li>
    <li>
      <button type="button" class="btn btn-primary btn-color" @click="resetFilters">Reset</button>
    </li>
  </ul>
</template>

<script>
  import { ref, computed } from "vue"
  import { useRouter, useRoute } from 'vue-router'
  import store from '@/store'
  import useSections from '@/composables/admin/assessments.composable'
  import useEventsBus from '@/composables/common/eventbus.composable'

  export default {
    name: 'HeaderToggle',
    setup() {
      const route = useRoute()
      const { emit } = useEventsBus()

      const resetFilters = () => {
        store.commit('setFilters', { department: '', priority: '', status: '' } )
        emit('filterChanged')
      }

      const filterData = () => {
        emit('filterChanged')
      }

      return { route, store, filterData, resetFilters }
    }
  }
</script>