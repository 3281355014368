import { ref, watch, computed } from 'vue'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/companies'
const modelName = 'Companies'

export default function useCompanies() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const companiesList = ref([])
  const company = ref({ 
    name: '', 
    description: '', 
    website: '', 
  })
  const standardAssessments = ref([])

  const getIndex = () => {
    secured.get(controllerUrl).then(response => {
      if (response.status === 200) {
        companiesList.value = response.data.companies
      } else { notifyError(modelName, 'Failed to load companies') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getCompany = (companyId) => {
    secured.get(`${controllerUrl}/${companyId}`).then(response => {
      if (response.status === 200) {
        company.value = response.data
      } else { notifyError(modelName, 'Failed to load company') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const createCompany = (payload, addUsers, assessmentIds) => {
    delete payload.company_vendors
    delete payload.company_evaluators
    secured.post(controllerUrl, { company: payload, add_users: addUsers, assessment_ids: assessmentIds }).then(response => {
      if (response.status === 201) {
        companiesList.value.push(response.data)
        company.value = response.data
        router.push('/companies')
      } else { notifyError(modelName, 'Failed to create company') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const updateCompany = (payload, addUsers, assessmentIds) => {
    delete payload.company_vendors
    delete payload.company_evaluators
    secured.put(`${controllerUrl}/${payload.id}`, { company: payload, add_users: addUsers, assessment_ids: assessmentIds }).then(response => {
      if (response.status === 200) {
        company.value = response.data
        notifySuccess(modelName, 'Company updated successfully')
        router.push('/companies')
      } else { notifyError(modelName, 'Failed to update company') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getStandardAssessments = () => {
    secured.get(`${controllerUrl}/standard_assessments`).then(response => {
      if (response.status === 200) {
        standardAssessments.value = response.data
      } else { notifyError(modelName, 'Failed to load standard assessments') }
    }).catch(error => { notifyError(modelName, error) })
  }

  return {
    getIndex, companiesList, getCompany, company, createCompany, updateCompany, getStandardAssessments, standardAssessments
  }  
}