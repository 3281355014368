<template>
  <div class="masonry-item col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-10">
          <div class="peers fxw-nw jc-sb ai-c bgc-white">
            <div class="peer" style="width: 90% !important">
              <h5 class="lh-1 mB-0" style="color: black;">{{ itemTitle }}</h5>
            </div>
            <div class="peer float-end" style="width: 10% !important">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-check form-switch">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      role="switch" 
                      v-model="item.is_selected"
                      @change="itemChanged"
                    />
                  </div>
                </div>
                <div class="col-md-6 float-start">
                  <a href="javascript:void(0)" data-bs-toggle="modal" :data-bs-target="`#edit_section_${item.identifier}`">
                    <i class="ti ti-pencil"></i>
                  </a>
                </div>
              </div>
              <SectionForm :item="item" :key="`#edit_section_${item.identifier}`" />
            </div>
          </div>
          <div class="peers fxw-nw jc-sb ai-c bgc-grey-200 mB-20 mT-20 pT-10 pL-10 pB-10" v-for="(question, key, idx) in questions">
            <div class="peer pR-10 pL-10" style="width: 90% !important">
              <h6 class="lh-1 mB-0" style="color: black;">{{ question.body }}</h6>
            </div>
            <div class="peer" style="width: 10% !important">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-check form-switch">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      role="switch" 
                      v-model="question.is_selected"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <a href="javascript:void(0)" data-bs-toggle="modal" :data-bs-target="`#edit_question_${question.identifier}`">
                    <i class="ti ti-pencil"></i>
                  </a>
                </div>
                <QuestionForm :question="question" :key="`#edit_question_${question.identifier}`" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import useEventsBus from '@/composables/common/eventbus.composable'
  import SectionForm from '@/components/assessments/SectionForm'
  import QuestionForm from '@/components/assessments/QuestionForm'
  export default {
    props: ['item', 'itemTitle', 'questions', 'showEdit', 'itemType'],
    components: { SectionForm, QuestionForm },
    setup(props) {
      const { emit } = useEventsBus()
      const editSection = () => {
        emit('editSectionData')
      }

      const itemChanged = () => {
        const isSelected = props.item.is_selected
        if (props.itemType == 'section') {
          const subSectionKeys = Object.keys(props.item.sub_sections_attributes)
          for (var i = 0; i < subSectionKeys.length; i++) {
            const subSection = props.item.sub_sections_attributes[subSectionKeys[i]]
            subSection.is_selected = isSelected
            const questionKeys = Object.keys(subSection.question_attributes)
            for (var j = 0; j < questionKeys.length; j++) {
              const question = subSection.question_attributes[questionKeys[j]]
              question.is_selected = isSelected
            }
          }
        } else if (props.itemType == 'sub_section'){
          const questionKeys = Object.keys(props.item.question_attributes)
          for (var j = 0; j < questionKeys.length; j++) {
            const question = props.item.question_attributes[questionKeys[j]]
            question.is_selected = isSelected
          }
        }
      }

      return { editSection, itemChanged }
    }
  }
</script>