<template>
  <div class="layer w-100 fxg-1 scrollable pos-r ps ps--active-y">
    <div class="accordion m-10" :id="`accordian_for_${section.id}`" v-for="(section, idx) in sections" :key="section.id" @click="loadSection(section, idx)">
      <div class="accordion-item">
        <h3 class="accordion-header" id="headingOne">
          <button 
            class="accordion-button" 
            :class="{ collapsed: section.id != currentSection.id }"
            type="button" 
            data-bs-toggle="collapse" 
            style="background-color: #fff !important;" 
            :data-bs-target="`#accordian_section_data_${section.id}`"
          >
            {{ section.name }}
          </button>
        </h3>
        <div :id="`accordian_section_data_${section.id}`" 
          class="collapse" 
          :class="{ show: section.id === currentSection.id }"
          :data-parent="`accordian_for_${section.id}`"
          >
          <div class="accordion-body">
            <div v-for="sub_section in section.sub_sections">
               <div class="layer mB-10 w-100">
                <h6 class="mB-5">{{ sub_section.name }}</h6>
                <small class="fw-600 c-grey-700">{{ getPercentCompleted(sub_section, section) }}% Completed</small>
                <div class="progress mT-10">
                  <div 
                    class="progress-bar bgc-deep-purple-500" 
                    role="progressbar"
                    aria-valuemin="0" 
                    aria-valuemax="100" 
                    :style="`width:${getPercentCompleted(sub_section, section)}%`"><span class="sr-only">{{ getPercentCompleted(sub_section, section) }}% Complete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, watch } from "vue"
  import { useRouter, useRoute } from 'vue-router'
  import useScrollable from '@/composables/common/scroll.composable'

  export default {
    props: ['sections', 'currentSection'],
    setup(props, { emit } ) {
      const route = useRoute()
      const sections = ref(props.sections)
      const { loadScrollbar } = useScrollable()
      
      onMounted(()=> {
        loadScrollbar()
      })

      const loadSection = (section, idx) => {
        emit('sectionClick', section, idx)
      }

      const getPercentCompleted = (subSection, section) => {
        const totalQuestions = section.questions.filter(question => {
          return question.question_data.sub_section_id === subSection.id
        }).length 
        const totalAnswered = section.questions.filter(question => {
          return question.question_data.sub_section_id === subSection.id && question.response_data.id != null
        }).length
        return Math.ceil((totalAnswered / totalQuestions) * 100)
      }

      return {
        sections, loadSection, getPercentCompleted
      }
    }
  }
</script>

<style>
  .accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125);
  }
  .accordion-item {
    border: none;
  }
</style>