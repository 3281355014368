<template>
  <div class="peer peer-greed" id="chat-box" :key="`questions_list_${section.id}`">
    <div class="layers h-100">
      <div class="layer w-100 fxg-1 bgc-grey-100">
        <div class="p-10">
          <div class="bgc-white bd mB-10 p-10" v-for="(question, idx) in section.questions" :key="`question_${question.id}`" :id="`idx_${idx}`">
            <div class="row" :class="{ 'mB-40': idx == section.questions.length - 1 }">
              <div class="col-md-12">
                <QuestionForm :question="question" :section="section" :assessment="assessment" :index="idx + 1"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, refs, onMounted, reactive, watch, nextTick, inject } from "vue"
  import QuestionForm from "@/components/common/QuestionForm"
  import useEventsBus from '@/composables/common/eventbus.composable'

  export default {
    name: 'Header',
    props: ['section', 'assessment'],
    components: {
      QuestionForm
    },
    setup(props) {
      
    }
  }
</script>