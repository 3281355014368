<template>
  <div class="email-wrapper row remain-height bgc-white ov-h">
    <div class="email-list h-100 layers">
      <div class="layer w-100">
        <div class="bgc-white-100 ai-c jc-sb fxw-nw bdB" style="padding: 20px 20px 13px 20px;">
          <div class="peers ai-c jc-sb">
            <div class="peers peer-greed">
              <h4 class="c-grey-900">Plugins</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="layer w-100 fxg-1 scrollable pos-r ps" v-if="apiPlugins.length > 0">
        <div class="" v-for="(plugin, index) in apiPlugins" :class="{selectedColor: plugin.id === selectedPlugin.id }">
          <div class="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p" @click="pluginSelected(plugin)">
            <div class="peer peer-greed ov-h">
              <h5 class="fsz-def tt-c c-grey-900" style="margin-bottom: 0px !important">{{ plugin.name }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="email-content h-100" v-if="selectedPlugin.id">
      <div class="layer w-100">
        <div class="bgc-white" style="padding: 20px 20px 13px 20px;">
          <div class="peers ai-c jc-sb">
            <div class="peers peer-greed">
              <h4 class="c-grey-900">Configure Plugin</h4>
            </div>
            <div class="peer">
              <a href="javascript:history.back()" class="btn cur-p btn-info btn-color"> <span class="icon-holder"><i class="c-white ti-angle-left"></i></span> Back </a>&nbsp;
              <button type="button" class="btn cur-p btn-success btn-color" @click="submitControls">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="h-100 scrollable pos-r ps">
        <div class="email-content-wrapper">
          <div class="bdT pX-25 pY-30">
            <TrelloForm  v-if="selectedPlugin.name == 'Trello'" :pluginData="pluginData"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
  .selectedColor {
    background-color: #f8f9fa;
  }
</style>

<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useAutomatedApis from '@/composables/admin/automatedapi.composable'
  import useScrollable from '@/composables/common/scroll.composable'
  import useEventsBus from '@/composables/common/eventbus.composable'
  import TrelloForm from '@/components/plugins/Trello'
  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { TrelloForm },
    setup() {
      const route = useRoute()
      const { getApiPlugins, apiPlugins } = useAutomatedApis()
      const { loadScrollbar } = useScrollable()
      const selectedPlugin = ref({})
      const { bus } = useEventsBus()
      const editSection = ref({})
      const showEditPlugin = ref(false)
      const pluginData = ref({})

      onMounted(()=> {
        getApiPlugins(route.params.id, route.params.api_id)
      })

      const pluginSelected = (plugin) => {
        selectedPlugin.value = plugin
        pluginData.value = JSON.parse(selectedPlugin.value.config)
        showEditPlugin.value = true
        loadScrollbar()
      }

      // const submitControls = () => {
      //   updateAssessment(assessmentData.value)
      // }

      return { apiPlugins, selectedPlugin, pluginSelected, showEditPlugin, pluginData }
    }
  }
</script>