<template>
	<div class="email-content open no-inbox-view">
  <div class="email-compose">
    <div class="d-n@md+ p-20"><a class="email-side-toggle c-grey-900 cH-blue-500 td-n" href="javascript:void(0)"><i class="ti-menu"></i></a></div>
    <form class="email-compose-body">
      <h4 class="c-grey-900 mB-20">{{ nodeData.title }}</h4>
      <div class="send-header">
        <div class="mb-3">
          <div class="form-check">
            <label class="form-label form-check-label">
              <input class="form-check-input" type="checkbox" v-model="nodeData.checked"> Show on Report
            </label>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="formFileSm" class="form-label">Vendor Logo</label>
            <input class="form-control form-control-sm mb-2" ref="vendorFile" type="file" @change="uploadVendorLogo($event)">
          </div>
          <div class="mb-3 col-md-6">
            <label for="formFileSm" class="form-label">Company Logo</label>
            <input class="form-control form-control-sm" id="formFileSm" ref="inputFile" type="file" @change="uploadCompanyLogo" :key="fileInputKeyCompany">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Title</label> 
            <input type="text" class="form-control" placeholder="nodeData.title" v-model="nodeData.title">
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Audit Observation Period</label> 
            <input type="text" class="form-control" placeholder="Audit Observation Period" v-model="reportConfig.audit_observation_period">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Next Audit Observation Date</label> 
          <VueDatePicker v-model="reportConfig.next_audit_observation_date"></VueDatePicker>
          </div>
          <div class="mb-3 col-md-6">
            <label for="formFileSm" class="form-label mb-2">Theme Color</label><br />
            <input type="color" v-model="reportConfig.primary_color" value="reportConfig.primary_color"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="form-label">Body</label>
            <Quill :nodeData="nodeData" />
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-info btn-color" @click="submitNode">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</template>
<script type="text/javascript">
  import { ref, shallowRef, onMounted, reactive } from "vue"
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import useReport from '@/composables/admin/reports.composable'
  import Quill from '@/components/common/ReportEditor'

  export default {
    props: ['nodeData', 'reportConfig'],
    components: { VueDatePicker, Quill },
    setup(props) {
      const { updateNode, uploadLogo, updateReportConfig } = useReport()
      const vendorFile = ref(null)
      const companyFile = ref(null)
      const fileInputKeyVendor = ref(0)
      const fileInputKeyCompany = ref(0)
      

      const submitNode = () => {
        updateReportConfig(props.reportConfig)
        updateNode(props.nodeData)
      }

      const uploadCompanyLogo = (event) => {
        uploadLogo(event.target.files[0]).then(response => {
          if (response.status === 201) {
            props.reportConfig.company_logo_id = response.data.id
            updateReportConfig(props.reportConfig)
          }
        })
      }

      const uploadVendorLogo = (event) => {
        uploadLogo(event.target.files[0]).then(response => {
          if (response.status === 201) {
            props.reportConfig.vendor_logo_id = response.data.id
            updateReportConfig(props.reportConfig)
          }
        })
      }

      return { submitNode, vendorFile, companyFile, uploadCompanyLogo, uploadVendorLogo, fileInputKeyVendor, fileInputKeyCompany}
    }
  }
</script>