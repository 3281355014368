<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Assessment Name</th>
        <th scope="col">Vendor Name</th>
        <th scope="col">Deadline</th>
        <th scope="col">Status</th>
        <th scope="col">Department</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(assessment, idx) in assessments">
        <th scope="row">{{ idx + 1 }}</th>
        <td>{{ assessment.assessment.name }}</td>
        <td>{{ assessment.assessment.vendor_name }}</td>
        <td>{{ assessment.assessment.deadline}}</td>
        <td>{{ toCamel(assessment.assessment.status) }}</td>
        <td>
          <router-link :to="{ 
              name: 'DepartmentAddUser', 
              params: { id: assessment.id } 
            }"
          >
            Add
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import router from '@/router'
  export default {
    props: ['assessments'],
    setup(props) {
      const toCamel = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      const loadAssessment = (assessment) => {
        router.push({name: 'EvaluatorAssessmentsControl', params: {evaluator_assessment_id: assessment.id}})
      }

      return {
        toCamel, loadAssessment
      }
    }
  }
</script>