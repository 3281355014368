import { ref, watch, computed } from 'vue'
import router from '@/router'
import store from '@/store'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const modelName = 'Companies'

export default function useAssessments() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const assessments = ref([])
  const assessmentData = ref({})
  const route = useRoute()

  const getIndex = (assessmentStatus) => {
    secured.get(`/companies/${route.params.id}/assessments`, {params: { query_options: { status: assessmentStatus, company_id: route.params.id } }}).then(response => {
      if (response.status === 200) {
        assessments.value = response.data
      } else { notifyError(modelName, 'Failed to load assessments') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getAssessment = (assessmentId) => {
    secured.get(`/companies/${route.params.id}/assessments/${assessmentId}`, { params: { methods: ['vendors_data', 'evaluators_data'] } } ).then(response => {
      if (response.status === 200) {
        assessmentData.value = response.data
      } else { notifyError(modelName, 'Failed to load assessment') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const updateAssessment = (payload) => {
    delete payload.evaluators_data
    delete payload.vendors_data
    secured.put(`/companies/${route.params.id}/assessments/${payload.id}`, { assessment: payload } ).then(response => {
      if (response.status === 200) {
        assessmentData.value = response.data
        notifySuccess('Assessments', 'Controls updated successfully')
        router.push(`send_assessment`)
      } else { notifyError(modelName, 'Failed to update assessment') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const sendAssessment = (assessmentId, payload) => {
    secured.post(`/companies/${route.params.id}/assessments/${assessmentId}/create_vendors_and_evaluators`, payload ).then(response => {
      if (response.status === 200) {
        assessmentData.value = response.data
        notifySuccess('Assessments', 'Controls updated successfully')
        router.push('/companies')
      } else { notifyError(modelName, 'Failed to update assessment') }
    }).catch(error => { notifyError(modelName, error) })
  }

  return {
    getIndex, assessments, getAssessment, assessmentData, updateAssessment, sendAssessment
  }  
}