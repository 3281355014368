<template>
  <div class="row">
    <div class="mb-3 col-md-4">
      <label class="form-label">Name</label>
      <input type="text" class="form-control" placeholder="Name" v-model="role.name">
    </div>
    <div class="mb-3 col-md-4">
      <label class="form-label">Email</label>
      <input type="email" class="form-control" placeholder="Email" v-model="role.email">
    </div>
    <div class="mb-3 col-md-4 mB-15">
      <label class="form-label">Select Department</label>
      <select class="form-control"  v-model="role.role">
        <option selected="selected" value="">Select Department...</option>
        <option :value="role" v-for="role in assessment.assessment.departments">{{ role }}</option>
      </select>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  export default {
    props: ['role', 'assessment'],
    setup() {

    }
  }
</script>