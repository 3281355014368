<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
    />
    <Editor
      style="height: 500px; overflow-y: hidden;"
      v-model="nodeData.body"
      :defaultConfig="editorConfig"
      @onCreated="handleCreated"
    />
  </div>
</template>
<script type="text/javascript">
  import '@wangeditor/editor/dist/css/style.css' // import css
  import { onBeforeUnmount, ref, shallowRef, onMounted, nextTick } from 'vue'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { i18nChangeLanguage } from '@wangeditor/editor'
  import { IEditorConfig } from '@wangeditor/editor'
  import { DomEditor } from '@wangeditor/editor'
  import store from "@/store"

  export default {
    props: ['nodeData'],
    components: { Editor, Toolbar },
    setup(props) {
      const editorRef = ref()
      const toolbarConfig = { excludeKeys: ['fontFamily', 'lineHeight', 'fullScreen', 'group-video', 'insertImage', 'emotion', 'todo'] }
      const API_URL = process.env.VUE_APP_API_URL
      const editorConfig = { 
        placeholder: 'Type here...',
        MENU_CONF: {
          uploadImage: {
            server: `${process.env.VUE_APP_API_URL}/documents`,
            fieldName: 'file',
            headers: {
              'Authorization': store.getters.authorizationToken 
            },
            customInsert(res, insertFn) {
              insertFn(res.public_url.replace('https', 'http'), '', res.public_url.replace('https', 'http'))
            }
          }
        } 
      }

      onMounted(() => {
        i18nChangeLanguage('en')
      })

      onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
      })

      const handleCreated = (editor) => { 
        editorRef.value = editor
      }

      return { editorRef, toolbarConfig, editorConfig, handleCreated }
    }
  }
</script>