<template>
  <div class="col-md-12">
    <div class="bgc-white p-20">
      <div class="peers ai-c jc-sb fxw-nw">
        <div class="peer">
          <div class="btn-group" role="group">
            <h4 class="c-grey-900 mB-20">Evidence Center</h4>
          </div>
        </div>
        <div class="gap-10 peers">
          <div class="peer">
            <button type="button" class="btn cur-p btn-primary btn-color" @click="showAddNewFolder">New Evidence</button>
          </div>
        </div>
      </div>
      <div class="bgc-white p-20 bd mB-20" v-if="addNewFolder">
        <form>
          <div class="mb-3">
            <label class="form-label">Evidence Name</label> 
            <input type="text" class="form-control" placeholder="Enter Evidence Name" v-model="newFolder.name"> 
          </div>
          <div class="gap-10 peers">
            <div class="peer">
              <button type="button" class="btn btn-primary btn-color" @click="createNewFolder">Submit</button>
            </div>
            <div class="peer">
              <button type="button" class="btn btn-primary btn-color" @click="showAddNewFolder">Cancel</button>
            </div>
          </div>
        </form>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr v-for="folder in foldersList">
            <td width="95%">
              <router-link 
                :to="{name: 'FolderDetail', params: { id: folder.id }, query: { for_assessment: 0 } }" 
                tag="button"> 
                {{ folder.name }} 
              </router-link>
            </td>
          </tr>
          <tr v-for="folder in assessmentFolderList">
            <td width="95%">
              <router-link 
                :to="{name: 'FolderDetail', params: { id: folder.id }, query: { for_assessment: 1} }" 
                tag="button"> 
                {{ folder.name }} 
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, computed, nextTick } from "vue"
  import store from '@/store'
  import useFolders from '@/composables/admin/folders.composable'
  
  export default {
    name: 'Header',
    setup() {
      const { getFolders, foldersList, assessmentFolderList, createFolder } = useFolders()
      const addNewFolder = ref(false)
      const newFolder = ref({
        user_id: store.getters.loggedInUser.id,
        name: ''
      })

      onMounted(()=> {
        getFolders()
      })

      const showAddNewFolder = () => {
        addNewFolder.value = !addNewFolder.value
      }

      const createNewFolder = () => {
        showAddNewFolder()
        createFolder(newFolder)
        newFolder.value.name = ''
      }

      return { foldersList, assessmentFolderList, addNewFolder, newFolder, showAddNewFolder, createNewFolder, store }
    }
  }
</script>