<template>
  <div 
    class="modal fade" 
    :id="`replicate_option_for_${assessment.id}`" 
    :key="`replicate_option_for_${assessment.id}`" 
    tabindex="-1" 
    aria-labelledby="exampleModalLabel" 
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select option to replicate</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div class="form-check">
              <label class="form-label form-check-label">
                <input class="form-check-input" type="radio" name="replicateUserDataRadio" id="replicateUserDataRadio" value="true" v-model="replicateUserData">
                Replicate with user data
              </label>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-check">
              <label class="form-label form-check-label">
                <input class="form-check-input" type="radio" name="replicateUserDataRadio" id="replicateUserDataRadio" value="false" v-model="replicateUserData">
                Replicate without user data
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal" @click="replicateAssessmentToCompany">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  import useEvaluatorAssessments from '@/composables/admin/evaluators.assessments.composable'

  export default {
    props: ['replicateUserData', 'assessment'],
    setup(props) {
      const replicateUserData = ref(false)
      const assessment = ref(props.assessment)
      const { replicateAssessment } = useEvaluatorAssessments()

      const replicateAssessmentToCompany = () => {
        console.log(replicateUserData)
        replicateAssessment(props.assessment.id, replicateUserData.value)
      }

      return {
        replicateUserData, assessment, replicateAssessmentToCompany
      }
    }
  }
</script>