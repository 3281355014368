<template>
  <div class="col-md-12" v-if="folder">
    <div class="bgc-white p-20">
      <div class="peers ai-c jc-sb fxw-nw">
        <div class="peer">
          <div class="btn-group" role="group">
            <h4 class="c-grey-900 mB-20" v-if="route.query.for_assessment == 0">
              {{ folder.name }}
              <a href="javascript:void(0)" class="td-n c-deep-purple-500 cH-blue-500 fsz-md p-5" @click="editFolder">
                <i class="ti-pencil"></i>
              </a>
            </h4>
          </div>
        </div>
        <div class="gap-10 peers" v-if="route.query.for_assessment == 0">
          <div class="peer">
            <button type="button" class="btn cur-p btn-primary btn-color" @click="showUploadFile">Upload File</button>
          </div>
          <div class="peer">
            <button type="button" class="btn cur-p btn-primary btn-color" @click="showAddNewFolder">New Folder</button>
          </div>
        </div>
      </div>
      <div class="bgc-white p-20 bd mB-20" v-if="addUploadFile">
        <h6 class="c-grey-900">Upload File</h6>
        <div class="mT-30">
          <div class="layer w-100">
            <div class="p-20 bdT bgc-white">
              <div class="pos-r">
                <input class="form-control form-control-sm" id="formFileSm" ref="inputFile" type="file" @change="uploadFile" :key="fileInputKey">
                <button type="button" class="btn btn-primary bdrs-50p w-2r p-0 h-2r pos-a r-1 t-1 btn-color" @click="submitFile">
                  <i class="fa fa-paper-plane-o"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgc-white p-20 bd mB-20" v-if="addNewFolder">
        <h6 class="c-grey-900">Create Folder</h6>
        <div class="mT-30">
          <form>
            <div class="mb-3">
              <label class="form-label">Folder Name</label> 
              <input type="text" class="form-control" placeholder="Enter Folder Name" v-model="newFolder.name"> 
            </div>
            <div class="gap-10 peers">
              <div class="peer">
                <button type="button" class="btn btn-primary btn-color" @click="createNewFolder">Submit</button>
              </div>
              <div class="peer">
                <button type="button" class="btn btn-primary btn-color" @click="showAddNewFolder">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table class="table table-bordered" v-if="folder">
        <thead>
          <tr>
            <th scope="col" colspan="2">
              <router-link 
                :to="{name: 'FolderDetail', params: { id: folder.parent_id }}" 
                tag="button" v-if="folder.parent_id"> 
                  <i class="ti ti-arrow-left"></i> 
              </router-link>
              <router-link 
                :to="{path: '/evidence_center'}" 
                tag="button" v-else> 
                  <i class="ti ti-arrow-left"></i> 
              </router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sub_folder in folder.sub_folders">
            <td width="95%">
              <router-link 
                :key="route.fullPath" 
                :to="{ name: 'FolderDetail', params: { id: sub_folder.id }}" 
                tag="button"
              > 
                {{ sub_folder.name }} 
              </router-link>
            </td>
            <td width="5%" style="text-align: center"><i class="ti-more-alt"></i></td>
          </tr>
          <tr v-for="document in folder.documents">
            <td width="95%" colspan="2">
              <a v-bind:href="document.public_url" target="_blank">
                <span>{{ document.original_file_name }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, computed, watch } from "vue"
  import store from '@/store'
  import useFolders from '@/composables/admin/folders.composable'
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useNotifier from '@/composables/common/notify.composable'

  export default {
    name: 'Header',
    setup(props) {
      const route = useRoute()
      const { getFolder, folder, createFolder, uploadAttachment, updateFolder, getAssessmentFolder } = useFolders()
      const { notifySuccess, notifyWarn, notifyError } = useNotifier()
      const addNewFolder = ref(false)
      const addUploadFile = ref(false)
      const fileInputKey = ref(0)
      const file = ref(null)

      const newFolder = ref({
        user_id: store.getters.loggedInUser.id,
        name: '',
        parent_id: route.params.id
      })

      onMounted(()=> {
        if (route.query.for_assessment == 1) {
          getAssessmentFolder(route.params.id)
        } else {
          getFolder( route.params.id )
        }
      })

      const editFolder = () => {
        addNewFolder.value = !addNewFolder.value
        newFolder.value = folder.value
      }

      const showAddNewFolder = () => {
        addNewFolder.value = !addNewFolder.value
        newFolder.value = {
          user_id: store.getters.loggedInUser.id,
          name: '',
          parent_id: route.params.id
        }
      }

      const showUploadFile = () => {
        addUploadFile.value = !addUploadFile.value
      }

      watch(route, ( newValue ) => {
        getFolder( route.params.id )
      })

      const createNewFolder = () => {
        if (newFolder.value.id) {
          updateFolder(newFolder.value).then(response => {
            if (response.status == 200) {
              folder.value = response.data
              showAddNewFolder()
            } else {
              notifyWarn('Folder', 'Failed to update folder')
            }
          })
        } else {
          createFolder(newFolder)
          location.reload()
        }
      }

      const uploadFile = (event) => {
        file.value = event.target.files[0]
      }

      const submitFile = async () => {
        uploadAttachment(file.value).then(response => {
          if (response.status === 201) {
            folder.value.document_ids.push(response.data.id)
            updateFolder(folder.value).then(response => {
              if (response.status == 200) {
                folder.value = response.data
                fileInputKey.value = fileInputKey.value + 1
                showUploadFile()
              } else {
                notifyWarn('Attachment', 'Failed to upload attachment')
              }
            })
          } else {
            notifyWarn('Attachment', 'Failed to upload attachment')
          }
        })
      }

      return { 
        addNewFolder, 
        addUploadFile,
        folder, 
        newFolder, 
        showAddNewFolder, 
        createNewFolder, 
        route,
        showUploadFile,
        fileInputKey,
        submitFile,
        uploadFile,
        editFolder,
        store
      }
    }
  }
</script>