<template>
	<BaseForm :nodeData="nodeData" :reportConfig="reportConfig" @updateNode="submitNode"/>
</template>
<script type="text/javascript">
  import BaseForm from '@/components/reports/BaseForm'
  import useReport from '@/composables/admin/reports.composable'

  export default {
    props: ['nodeData', 'reportConfig'],
    components: { BaseForm },
    setup(props) {
      const { updateNode } = useReport()
      const submitNode = () => {
        updateNode(props.nodeData)
      }
      return { submitNode }
    }
  }
</script>