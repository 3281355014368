<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Assessments</h4>
          </div>
        </div>
        <AssessmentTable :assessments="assessments" :isDraft=true />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch, computed, nextTick } from "vue"
  import useAssessments from '@/composables/admin/assessments.composable'
  import store from '@/store'
  import router from '@/router'
  import AssessmentTable from '@/components/assessments/Index'

  export default {
    components: { AssessmentTable },
    name: 'Company',
    setup() {
      const { getIndex, assessments } = useAssessments()
      const assignedAssessments = ref(true)
      const draftAssessments = ref(false)

      onMounted(()=> {
        getIndex()
      })

      const loadAssessments = (assessmentStatus) => {
        draftAssessments.value = !draftAssessments.value
        assignedAssessments.value = !assignedAssessments.value
      }

      const showAssessments = (company) => {
        store.commit("setCurrentCompany", company)
        router.push({name: 'CompanyAssessment', params: {id: company.id}})
      }

      return { 
        assessments, 
        showAssessments,
        assignedAssessments,
        draftAssessments,
        loadAssessments
      }
    }
  }
</script>