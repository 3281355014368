<template>
  <div class="container" style="display: flex; justify-content: center; align-items: center; height: 100vh">
    <slot />
  </div>
</template>

<script>
  import '@/assets/styles/index.scss'
  import '@/assets/scripts/index.js'
  import { reactive } from "vue"
  import store from '@/store'
  export default {
    setup() { }
  }
</script>