<template>
  <div class="row">
    <div class="col-md-6">
      <div class="bgc-white p-20 bd">
        <h6 class="c-grey-900">Configure Source</h6>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="Name" v-model="pluginData.source.name">
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Path</label>
            <input type="text" class="form-control" placeholder="Path" v-model="pluginData.source.path" disabled>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">API Key</label>
            <input type="text" class="form-control" placeholder="API Key" v-model="pluginData.source.spec.api_key">
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Token</label>
            <input type="text" class="form-control" placeholder="Path" v-model="pluginData.source.spec.token">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Last Update Source</label>
            <input type="text" class="form-control" placeholder="Last Update Source" v-model="pluginData.source.last_update_source" disabled>
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Version</label>
            <input type="text" class="form-control" placeholder="version" v-model="pluginData.source.version" disabled>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-12">
            <label class="form-label">ENV</label>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="env in pluginData.source.env">
                  <td><input type="text" class="form-control" placeholder="Name" v-model="env.name"></td>
                  <td><input type="text" class="form-control" placeholder="Value" v-model="env.value"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="bgc-white p-20 bd">
        <h6 class="c-grey-900">Configure Destination</h6>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'TrelloPlugin',
    props: ['pluginData'],
    setup(props) {
      return {  }
    }
  }
</script>