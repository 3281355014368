<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Add Vendors and Evaluators</h4>
          </div>
          <div class="peer">
            <a href="javascript:history.back()" class="btn cur-p btn-info btn-color"> <span class="icon-holder"><i class="c-white ti-angle-left"></i></span> Back </a>&nbsp;
            <button type="button" class="btn cur-p btn-success btn-color" @click="sendAssessmentToUsers">Send</button>
          </div>
        </div>
        <div class="row mB-20">
          <div class="col-md-1">
            Send Invitation
          </div>
          <div class="col-md-1">
            <div class="form-check form-switch">
              <input 
                class="form-check-input" 
                type="checkbox" 
                role="switch" 
                v-model="sendInvitation"
              />
            </div>
          </div>
        </div>
        <table class="table table-striped table-bordered" v-if="assessmentData">
          <thead>
            <tr>
              <th scope="col" colspan="3">Vendors</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vendor, idx) in assessmentData.vendors_data">
              <th scope="row" style="text-align: center; vertical-align: middle">#</th>
              <td>{{ vendor.name }}</td>
              <td>{{ vendor.email }}</td>
            </tr>
            <tr v-for="(vendor, idx) in vendors">
              <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + 1 }}</th>
              <td><input type="text" class="form-control" placeholder="Name" v-model="vendor.name"></td>
              <td><input type="email" class="form-control" placeholder="Email" v-model="vendor.email"></td>
            </tr>
            <tr>
              <td colspan="3">
                <button type="button" class="btn btn-secondary btn-color" @click="addMoreUsers(true)">Add More</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-striped table-bordered" v-if="assessmentData">
          <thead>
            <tr>
              <th scope="col" colspan="3">Evaluators</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(evaluator, idx) in assessmentData.evaluators_data">
              <th scope="row" style="text-align: center; vertical-align: middle">#</th>
              <td>{{ evaluator.name }}</td>
              <td>{{ evaluator.email }}</td>
            </tr>
            <tr v-for="(evaluator, idx) in evaluators">
              <th scope="row" style="text-align: center; vertical-align: middle">{{ idx + 1 }}</th>
              <td><input type="text" class="form-control" placeholder="Name" v-model="evaluator.name"></td>
              <td><input type="email" class="form-control" placeholder="Email" v-model="evaluator.email"></td>
            </tr>
            <tr>
              <td colspan="3">
                <button type="button" class="btn btn-secondary btn-color" @click="addMoreUsers(false)">Add More</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useAssessments from '@/composables/admin/assessments.composable'

  import AddUser from '@/components/companies/AddUser'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'SendAssessment',
    components: { AddUser },
    setup() {
      const route = useRoute()
      const vendors = ref([])
      const evaluators = ref([])
      const sendInvitation = ref(false)

      const { getAssessment, assessmentData, sendAssessment } = useAssessments()

      onMounted(()=> {
        getAssessment(route.params.assessment_id)
      })

      const addMoreUsers = (addVendor) => {
        if (addVendor == true) {
          vendors.value.push({ name: '', email: '' })
        } else {
          evaluators.value.push({ name: '', email: '' })
        }
      }

      const sendAssessmentToUsers = () => {
        const payload = ref({
          vendors: vendors.value,
          evaluators: evaluators.value,
          send_invitation: sendInvitation.value
        })
        sendAssessment(route.params.assessment_id, payload.value)
      }

      return { assessmentData, vendors, evaluators, sendInvitation, addMoreUsers, sendAssessmentToUsers }
    }
  }
</script>