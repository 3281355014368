<template>
  <div class="modal fade" :id="`edit_question_${question.identifier}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Question</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label class="form-label">Body</label>
              <input type="email" class="form-control" placeholder="Section name" v-model="question.body">
            </div>
            <div class="mb-3 col-md-6">
              <label class="form-label">Description</label>
              <input type="email" class="form-control" placeholder="Section name" v-model="question.description">
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-6">
              <label class="form-label">Evidence Description</label>
              <input type="email" class="form-control" placeholder="Section name" v-model="question.evidence_description">
            </div>
            <div class="mb-3 col-md-6">
              <label class="form-label">Evidence Expected</label>
              <input type="email" class="form-control" placeholder="Section name" v-model="question.evidence_expected">
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-6">
              <label class="form-label">Reference Link</label>
              <input type="email" class="form-control" placeholder="Section name" v-model="question.reference_link">
            </div>
          </div>
          <div class="row">
            <div v-for="(option, key, idx) in question.options_attributes" >
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">Option {{ idx + 1 }} Body</label>
                  <input type="email" class="form-control" placeholder="Section name" v-model="option.body">
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label">Option {{ idx + 1 }} Score</label>
                  <input type="email" class="form-control" placeholder="Section name" v-model="option.score">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, computed } from "vue"
  export default {
    props: ['question'],
    setup(props) {
      return {
        
      }
    }
  }
</script>