import { ref } from 'vue'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/documents'
const responseControllerUrl  = '/assessment_vendors'

export default function useAttachment() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  
  const uploadAttachment = (payload, vendorResponse) => {
    var formData = new FormData()
    formData.append("file", payload)
    return secured.post( controllerUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  const uploadResponse = (vendorResponse) => {
    return secured.patch(responseControllerUrl+'/'+vendorResponse.assessment_vendor_id+'/assessment_vendor_responses'+'/'+vendorResponse.id, {assessment_vendor_response: vendorResponse} )
  }

  return {
    uploadAttachment, uploadResponse
  }  
}