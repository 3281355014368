import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'
import loading from "@/composables/common/loader.composable"
import router from '../router'

const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "AD^&FDHJDIQ*IDVHVSA%$" 
})

const { notifySuccess, notifyWarn, notifyError } = useNotifier()

const controllerUrl  = '/users'

export default createStore({
  state: {
    authorizationToken: "",
    loggedInUser: {},
    isAuthenticated: false,
    currentAssessment: {},
    currentCompany: {},
    selectedRole: '',
    filters: { department: '', priority: '', status: '' },
    filtersData: { departments: [], priorities: [], statuses: [] }
  },

  mutations: {
    setAuthorizationToken: ( state, token ) => { state.authorizationToken = token },
    setLoggedInUser: ( state, loggedInUser ) => { state.loggedInUser = loggedInUser },
    setIsAuthenticated: ( state, isAuthenticated ) => { state.isAuthenticated = isAuthenticated },
    setCurrentAssessment: ( state, currentAssessment ) => { state.currentAssessment = currentAssessment },
    setCurrentCompany: ( state, currentCompany ) => { state.currentCompany = currentCompany },
    setSelectedRole: ( state, selectedRole ) => { state.selectedRole = selectedRole },
    setFilters: ( state, filters ) => { state.filters = filters },
    setFiltersData: ( state, filtersData ) => { state.filtersData = filtersData },
    setLogout: ( state ) => {
      state.authorizationToken = ""
      state.loggedInUser = {}
      state.isAuthenticated = false
      state.currentAssessment = {}
      state.currentCompany = {},
      state.selectedRole = ''
      state.filters = { department: '', priority: '', status: '' }
      state.filtersData = { departments: [], priorities: [], statuses: [] }
    }
  },

  actions: {
    logIn: async ({ commit, dispatch }, payload) => {
      commit("loading/setLoading", true)
      plain.post(controllerUrl + "/sign_in", { user: payload }).then(response => {
        if (response.status === 200) {
          commit("setAuthorizationToken", response.headers.authorization)
          dispatch("fetchUser")
        } else {
          notifyError('Authentication', 'Login failed')
          commit("loading/setLoading", false)
        }
      }).catch(error => {
        notifyError('Authentication', error.response.data.error)
        commit("loading/setLoading", false)
      })
    },

    roleSelected: async ({ commit, dispatch }, payload) => {
      commit("setSelectedRole", payload)
      router.push('/companies')
    },

    fetchUser: async ({ commit, state }) => {
      secured.get(controllerUrl + "/current_user").then(response => {
        if (response.status == 200) {
          commit("setLoggedInUser", response.data)
          commit("setIsAuthenticated", true)
          if (response.data.roles.length > 1) {
            router.push('/auth/select_role')
          } else { 
            commit("setSelectedRole", response.data.roles[0])
            router.push('/companies')
          }
        } else {
          notifyError('Authentication', 'Login failed')
        }
        commit("loading/setLoading", false)
      }).catch(error => {
        notifyError('Authentication', error.response.data.error)
        commit("loading/setLoading", false)
      })
    },

    signOut: async ({ commit }) => {
      secured.delete(controllerUrl + "/sign_out").then(response => {
        if (response.status === 200) {
          commit("setLogout")
          router.push({ path: '/auth/login' })
        }
      }).catch(error => {
        notifyError('Authentication', error.response.data.error)
      })
    }
  },

  getters: {
    authorizationToken: (state) => { return state.authorizationToken },
    loggedInUser: (state) => { return state.loggedInUser },
    isAuthenticated: (state) => { return state.isAuthenticated },
    currentAssessment: (state) => { return state.currentAssessment },
    currentCompany: (state) => { return state.currentCompany },
    selectedRole: (state) => { return state.selectedRole },
    filters: (state) => { return state.filters },
    filtersData: (state) => { return state.filtersData }
  },

  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],

  modules: {
    loading
  }

})
