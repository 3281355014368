import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'
import useEventsBus from '@/composables/common/eventbus.composable'

const { notifySuccess, notifyWarn, notifyError } = useNotifier()
const reportConfigsUrl  = '/report_configurations'
const reportSectionsUrl  = '/report_sections'
const documentsUrl  = '/documents'
const reportsUrl = 'reports'
const vendorAssessmentsUrl = '/assessment_vendors'
const evaluatorAssessmentsUrl = '/assessment_evaluators'

const modelName = 'Report'

export default function useReports() {
  const route = useRoute()
  const reportConfig = ref({})
  const reportSections = ref([])
  const assessments = ref([])
  const assessment = ref({})
  const { emit } = useEventsBus()

  const assessmentsUrl = () => {
    if (store.getters.selectedRole == 'vendor') {
      return vendorAssessmentsUrl
    } else {
      return evaluatorAssessmentsUrl
    }
  }

  const getAssessments = () => {
    secured.get(assessmentsUrl()).then(response => {
      if (response.status === 200) {
        assessments.value = response.data
      } else { notifyError(modelName, 'Failed to load assessments') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getAssessment = (assessmentVendorId) => {
    secured.get(`${assessmentsUrl()}/${assessmentVendorId}`).then(response => {
      if (response.status == 200) {
        assessment.value = response.data
      } else {
        notifyError('Assessment', 'Failed to fetch assessment')
      }
    }).catch(err => {
      notifyError('Assessment', err)
    })
  }

  const getReportConfig = () => {
    secured.get(`${reportConfigsUrl}/${route.params.id}`, { params: { for_vendor: store.getters.selectedRole == 'vendor' } }).then(response => {
      if (response.status === 200) {
      	reportConfig.value = response.data
        reportConfig.value.sections.forEach((section, index) => {
          reportSections.value.push(section)
          section.children.forEach((child, index) => {
            reportSections.value.push(child)
          })
          emit('loadFirstNode', reportConfig.value.sections[0])
        })
      } else { 
      	notifyError(modelName, 'Failed to load report') 
      }
    }).catch(error => { notifyError('Assessment', error) })
  }

  const updateNode = (nodeData, notify = true) => {
    secured.put(reportSectionsUrl + '/' + nodeData.id, { report_section: nodeData }).then(response => {
      if (notify) {
        if (response.status === 200) { notifySuccess('Report', 'Section updated successfully') } else { notifyError('Report', 'Failed to update section') }
      } 
    }).catch(error => { notifyError('Report', error) })
  }

  const createNode = (nodeData) => {
    secured.post(reportSectionsUrl, { report_section: nodeData }).then(response => {
      if (response.status === 200) {
        notifySuccess('Report', 'Section created successfully') 
        location.reload()
      } else { 
        notifyError('Report', 'Failed to create section') 
      }
    }).catch(error => { notifyError('Report', error) })
  }

  const uploadLogo = (payload) => {
    var formData = new FormData()
    formData.append("file", payload)
    formData.append('is_public_document', true)
    return secured.post( documentsUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  const updateReportConfig = (payload) => {
    secured.put(reportConfigsUrl + '/' + payload.id, { report_configuration: payload }).then(response => {
    }).catch(error => { notifyError('Report', error) })
  }

  const reportPreview = (reportId) => {
    window.open(`${process.env.VUE_APP_API_URL}/reports/${reportId}/preview.pdf`, '_blank')
  }

  const addVendorsByRole = (assessmentId, payload) => {
    return secured.post(`${assessmentsUrl()}/${assessmentId}/add_assessment_vendor_by_roles`, { vendors: payload })
  }

  return { 
    getAssessments, 
    assessments,
    assessment,
    getAssessment, 
    getReportConfig, 
    reportConfig, 
    updateNode, 
    createNode, 
    reportSections, 
    uploadLogo, 
    updateReportConfig, 
    reportPreview,
    addVendorsByRole 
  }  
}