<template>
  <li class="notifications dropdown">
    <a href="" class="dropdown-toggle no-after" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="ti-bell"></i>
    </a>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
      <li class="pX-20 pY-15 bdB">
        <i class="ti-bell pR-10"></i>
        <span class="fsz-sm fw-600 c-grey-900">Notifications</span>
      </li>
      <li>
        <ul class="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm">
          <li v-for="comment in notificationComments">
            <div class="peer peer-greed peers fxw-nw td-n p-10 bdB c-grey-800 cH-blue bgcH-grey-100">
              <span class="fw-500">{{ comment.user.name}}&nbsp;</span><br/>
              <span class="c-grey-600">{{ comment.body }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script type="text/javascript">
  import { ref, onMounted, reactive, defineExpose } from "vue"
  import store from '@/store'
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useScrollable from '@/composables/common/scroll.composable'
  import useComments from '@/composables/admin/comment.composable'
  export default {
    name: 'AdminNotifications',
    setup(props, context) {
      const { getNotifications, notificationComments } = useComments()

      onMounted(()=> {
        getNotifications()
      })

      return {
        notificationComments
      }
    }
  }
</script>