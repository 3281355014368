import { ref, watch, computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/assessment_vendors'
const modelName = 'Vendor Assessments'

export default function useVendorAssessments() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const route = useRoute()
  const assessments = ref([])
  const sections = ref([])
  const currentSectionIdx = ref(-1)
  const currentSection = ref({})
  const currentAssessment = ref({})

  const filteredFilters = () => {
    const result = {}
    for (const key in store.getters.filters) {
      if (store.getters.filters[key] !== null && store.getters.filters[key] !== undefined && store.getters.filters[key] !== '') {
          result[key] = store.getters.filters[key];
      }
    }
    return result
  }

  const getIndex = () => {
    secured.get(controllerUrl).then(response => {
      if (response.status === 200) {
        assessments.value = response.data
      } else { notifyError(modelName, 'Failed to load assessments') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getSections = () => {
    const apiUrl = `${controllerUrl}/${route.params.vendor_assessment_id}/assessment_wise_sections`
    var apiQuery = secured.get(apiUrl)
    if (store.getters.filters.department != '' || store.getters.filters.priority != '') {
      apiQuery = secured.get(apiUrl, {params: {search_query: filteredFilters()}})
    }
    apiQuery.then(response => {
      if (response.status === 200) {
        currentAssessment.value = response.data.assessment
        sections.value = response.data.sections
        if (sections.value.length > 0) {
          currentSectionIdx.value = 0
          currentSection.value = sections.value[0]  
        }
        store.commit('setFiltersData', {
          departments: currentAssessment.value.departments,
          priorities: currentAssessment.value.priorities,
          statuses: currentAssessment.value.statuses
        })
      } else { 
        notifyError(modelName, 'Failed to load sections')
      }
    }).catch(error => {
      notifyError(modelName, error)
    })
  }

  const prevClicked = () => {
    if (currentSectionIdx.value > 0) {
      currentSectionIdx.value = currentSectionIdx.value - 1
      currentSection.value = sections.value[currentSectionIdx.value]
      router.push({name: 'VendorAssessmentsControl', params: {vendor_assessment_id: route.params.vendor_assessment_id, section_id: currentSection.value.id}})
    }
  }

  const nextClicked = () => {
    if (currentSectionIdx.value < sections.value.length - 1) {
      currentSectionIdx.value = currentSectionIdx.value + 1
      currentSection.value = sections.value[currentSectionIdx.value]
      router.push({name: 'VendorAssessmentsControl', params: {vendor_assessment_id: route.params.vendor_assessment_id, section_id: currentSection.value.id}})
    }
  }

  const updateCurrentIndex = (idx) => {
    if (idx.value >= 0) {
      currentSectionIdx.value = idx.value
      currentSection.value = sections.value[idx.value]
    }
  }

  const sectionClicked = (section, idx) => {
    currentSectionIdx.value = idx
    currentSection.value = section
    router.push({name: 'VendorAssessmentsControl', params: {vendor_assessment_id: route.params.vendor_assessment_id, section_id: currentSection.value.id}})
  }

  const questionResponseValid = (question) => {
    if (question.question_data.input_type == 'boolean') {
      if (question.response_data.option_id > 0) { return true }  
    } else {
      if (question.response_data.justification != '') { return true }
    }
    return false
  }

  const sectionResponsesValid = (section) => {
    let validCount = 0
    section.questions.forEach((question, index) => { if (questionResponseValid(question) == true) { validCount += 1 } })
    return validCount === section.questions.length
  }

  const createResponse = (payload) => {
    return secured.post(`${controllerUrl}/${payload.assessment_vendor_id}/assessment_vendor_responses`, { assessment_vendor_response: payload })
  }

  const patchResponse = (payload) => {
    return secured.patch(`${controllerUrl}/${payload.assessment_vendor_id}/assessment_vendor_responses/${payload.id}`, { assessment_vendor_response: payload })
  }

  const saveSectionResponses = () => {
    if (sectionResponsesValid(currentSection.value) == true) {
      currentSection.value.questions.forEach((question, index) => {
        if ( question.response_data.id ) {
          patchResponse(question.response_data).then(response => {
            response.status === 200 ? question.response_data = response.data : notifyError('Response', 'Failed to update response')
          }).catch(error => { notifyError('Response', 'Failed to update response') })
        }
      })
      if (currentSectionIdx.value < sections.value.length - 1) {
        currentSectionIdx.value = currentSectionIdx.value + 1
        sectionClicked(sections.value[currentSectionIdx.value], currentSectionIdx.value)
      }
    } else {
      notifyError('Response', 'Please check if all questions are responded')
    }
  }

  const submitSectionResponses = () => {
    let completedSections = 0
    sections.value.forEach((section, index) => {
      if (sectionResponsesValid(section) == true) { completedSections += 1 }
    })
    if (completedSections == sections.value.length) {
      secured.put(`companies/${currentAssessment.value.company_id}/assessments/${currentAssessment.value.id}`, { assessment: { status: 'vendor_completed' } }).then(response => {
        if (response.status === 200) { 
          notifySuccess('Assessment', 'Assessment submitted successfully')
          router.push({name: 'VendorAssessmentsIndex', params: {id: store.getters.currentCompany.id}})
        } else { 
          notifyError('Section', 'Failed to submit assessment') 
        }
      }).catch(error => { notifyError('Assessment', error) })
    } else {
      notifyError('Response', 'Please check if all questions in all sections are responded')
    }
  }

  return {
    getIndex, 
    assessments, 
    currentAssessment,
    getSections,
    sections,
    currentSectionIdx,
    currentSection,
    currentAssessment,
    prevClicked,
    nextClicked, 
    sectionClicked,
    questionResponseValid,
    createResponse,
    patchResponse,
    saveSectionResponses,
    submitSectionResponses
  }  
}