<template>
  <div class="masonry-item col-md-12">
    <div class="bgc-white p-20 bd">
      <h6 class="c-grey-900">Add Role</h6>
      <div class="mT-30">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(role, idx) in assessment.assessment.role_wise_vendors" v-if="assessment.assessment">
              <th scope="row">{{ idx + 1}}</th>
              <td>{{ role.name }}</td>
              <td>{{ role.email }}</td>
              <td>{{ role.role }}</td>
            </tr>
            <tr v-for="(role, idx) in vendorRoles">
              <th scope="row">#</th>
              <td><input type="text" class="form-control" placeholder="Name" v-model="role.name"></td>
              <td><input type="email" class="form-control" placeholder="Email" v-model="role.email"></td>
              <td>
                <select class="form-control"  v-model="role.role">
                  <option selected="selected" value="">Select Department...</option>
                  <option :value="role" v-for="role in assessment.assessment.departments">{{ role }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="gap-10 peers">
          <div class="peer">
            <button type="button" class="btn btn-secondary btn-color" @click="addMore">Add More</button>
          </div>
          <div class="peer">
            <button type="button" class="btn btn-primary btn-color" @click="submitRole">Send Invitation</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, computed } from "vue"
  import store from '@/store'
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useReports from '@/composables/admin/reports.composable'
  import useNotifier from '@/composables/common/notify.composable'
  import DepartmentRoleForm from '@/components/common/DepartmentRoleForm'

  export default {
    name: 'VendorRole',
    components: { DepartmentRoleForm },
    setup() {
      const { getAssessment, assessment, addVendorsByRole } = useReports()
      const { notifySuccess, notifyWarn, notifyError } = useNotifier()
      const route = useRoute()
      const vendorRoles = ref([])
      
      onMounted(()=> {
        getAssessment(route.params.id)
      })

      const addMore = () => {
        vendorRoles.value.push({
          name: '',
          email: '',
          role: ''
        })
      }

      const submitRole = () => {
        var allRoleValid = 0
        vendorRoles.value.forEach((vendorRole, index) => {
          const emailValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(vendorRole.email))
          const nameValid = vendorRole.name != ''
          const roleValid = vendorRole.role != ''
          if (emailValid && nameValid && roleValid) {
            allRoleValid += 1
          }
        })
        if (allRoleValid === vendorRoles.value.length) {
          addVendorsByRole(route.params.id, vendorRoles.value).then(response => {
            if (response.status === 200) {
              notifySuccess('Role', 'Role created successfully')
              location.reload()
            }
          }).catch(err => {
            notifyError('Role', err)
          })
        } else {
          notifyError('Role', 'Please check if all fields are correct')
        }
      }

      return { assessment, vendorRoles, addMore, submitRole }
    }
  }
</script>