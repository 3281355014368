import { ref } from 'vue'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/users'

export default function useAuth() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()

  const payloadValid = (payload) => {
    if (payload.email === '') {
      notifyError('Email', 'Email address is required')
      return false
    } else if (payload.password === '') {
      notifyError('Password', 'Password is required')
      return false
    } else {
      return true
    }
  }

  const apiLogin = (payload) => {
    plain.post(controllerUrl + "/sign_in", { user: payload }).then(response => {
      store.commit("setAuthorizationToken", response.headers.authorization)
      fetchUser()
      notifySuccess('Authentication', 'Login successfull')
    }).catch(error => {
      notifyError('Authentication', error.response.data.error)
    })
  }

  const fetchUser = () => {
    secured.get(controllerUrl + "/current_user").then(response => {
      if (response.status === 200) {
        store.commit("setLoggedInUser", response.data)
        store.commit("setIsAuthenticated", true)
        router.push('/companies')
      }
    }).catch(error => {
      notifyError('Authentication', error.response.data.error)
    })
  }

  const logout = () => {
    secured.delete(controllerUrl + "/sign_out").then(response => {
      if (response.status === 200) {
        store.commit("setLogout")
        router.push({ path: '/auth/login' })
      }
    }).catch(error => {
      notifyError('Authentication', error.response.data.error)
    })
  }

  return {
    payloadValid, apiLogin, fetchUser, logout
  }  
}