<template>
	<div class="modal fade" 
    :id="`attachments_for_${section.id}_${question.question_data.id}`" 
    tabindex="-1" :aria-labelledby="`attachments_for_${section.id}_${question.question_data.id}`" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <small>Attachments / Evidence</small>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="peers fxw-nw pos-r">
            <div class="peer peer-greed" id="chat-box-popup">
              <div class="layers h-100">
                <div class="layer w-100 fxg-1 bgc-grey-200 scrollable pos-r ps">
                  <div class="p-20 gapY-15">
                    <div class="peers fxw-nw" v-for="document in question.response_data.documents" :key="`document_data_${document.id}`">
                      <div class="peer mR-10 mT-5">
                        <i class="ti ti-user" aria-hidden="true"></i>
                      </div>
                      <div class="peer peer-greed">
                        <div class="layers ai-fs gapY-5">
                          <div class="layer">
                            <div class="peers fxw-nw ai-c pY-3 pX-10 bgc-white bdrs-2 lh-3/2">
                              <div class="peer mR-10"><small>{{ document.created_at }}</small></div>
                              <div class="peer-greed">
                                <a v-bind:href="document.public_url" target="_blank">
                                  <span>{{ document.original_file_name }}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="layer w-100">
                  <div class="p-20 bdT bgc-white">
                    <div class="pos-r">
                      <input class="form-control form-control-sm" id="formFileSm" ref="inputFile" type="file" @change="uploadFile" :key="fileInputKey">
                      <button type="button" class="btn btn-primary bdrs-50p w-2r p-0 h-2r pos-a r-1 t-1 btn-color" @click="submitFile">
                        <i class="fa fa-paper-plane-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, computed } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useScrollable from '@/composables/common/scroll.composable'
  import useAttachments from '@/composables/admin/attachment.composable'
  import useNotifier from '@/composables/common/notify.composable'

  export default {
    props: ['question', 'section'],
    setup(props, context) {
      const { loadScrollbar } = useScrollable()
      const fileInputKey = ref(0)
      const file = ref(null)
      const fileName = computed(() => file.value?.name)
      const fileExtension = computed(() => fileName.value?.substr(fileName.value?.lastIndexOf(".") + 1))
      const fileMimeType = computed(() => file.value?.type)
      const { uploadAttachment, uploadResponse } = useAttachments()
      const { notifySuccess, notifyWarn, notifyError } = useNotifier()

      onMounted(()=> {
        loadScrollbar()
      })

      const uploadFile = (event) => {
        file.value = event.target.files[0]
        checkFileExtension()
      }

      const checkFileExtension = () => {
        if (['doc', 'docx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx', 'csv'].includes(fileExtension.value)) {
          return fileExtension.value
        } else {
          notifyWarn('Attachment', 'Only .doc, .docx, .ppt, .pptx, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .csv files are allowed')
          return ''
        }
      }

      const submitFile = async () => {
        if (checkFileExtension() != '') {
          uploadAttachment(file.value, props.question.response_data).then(response => {
            if (response.status === 201) {
              props.question.response_data.document_ids.push(response.data.id)
              uploadResponse(props.question.response_data).then(response => {
                if (response.status == 200) {
                  props.question.response_data = response.data
                  fileInputKey.value = fileInputKey.value + 1
                } else {
                  notifyWarn('Attachment', 'Failed to upload attachment')
                }
              })
            } else {
              notifyWarn('Attachment', 'Failed to upload attachment')
            }
          })
        } else {
          notifyWarn('Attachment', 'Only .doc, .docx, .ppt, .pptx, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .csv files are allowed')
        }
      }

      return { uploadFile, submitFile, fileInputKey }
    }
  }
</script>