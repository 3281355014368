import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import store from './store'

import Notifications from '@kyvg/vue3-notification'

import AuthLayout from '@/layouts/Auth'
import OtherLayout from '@/layouts/Other'

const app = createApp(App)
app.use(store).use(router)
app.use(Notifications)
app.component('auth-layout', AuthLayout)
app.component('other-layout', OtherLayout)
app.mount('#app')