<template>
  <div class="email-content h-100 scrollable pos-r ps">
    <div class="layer w-100">
      <div class="bgc-white" style="padding: 20px 20px 13px 20px;">
        <div class="peers ai-c jc-sb">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Select controls</h4>
          </div>
          <div class="peer">
            <a href="javascript:history.back()" class="btn cur-p btn-info btn-color"> <span class="icon-holder"><i class="c-white ti-angle-left"></i></span> Back </a>&nbsp;
            <button type="button" class="btn cur-p btn-success btn-color" @click="pushControls">Send Assessment</button>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100" v-if="selectedSection.name">
      <div class="email-content-wrapper">
        <div class="bdT pX-25 pY-30">
          <div class="row">
            <div class="col-md-12">
              <div class="gapY-15">
                <div class="peers fxw-nw">
                  <div class="peer peer-greed">
                    <div class="layers ai-fs gapY-5" v-if="showEditSection == false">
                      <RowItem :item="selectedSection" :itemTitle="selectedSection.name" :key="`section_${selectedSection.identifier}`" :showEdit='true' itemType="section"/>
                      <RowItem 
                        :item="sub_section" 
                        :itemTitle="sub_section.name" 
                        :questions="sub_section.question_attributes"
                        v-for="(sub_section, key, idx) in selectedSection.sub_sections_attributes" 
                        :key="`sub_section_${sub_section.identifier}`" 
                        itemType="sub_section"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useAssessments from '@/composables/admin/assessments.composable'
  import useScrollable from '@/composables/common/scroll.composable'
  import useEventsBus from '@/composables/common/eventbus.composable'
  import RowItem from '@/components/assessments/RowItem'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { RowItem },
    props: ['selectedSection', 'submitControls'],
    setup(props) {
      const route = useRoute()
      const showEditSection = ref(false)
      const { emit } = useEventsBus()
      const { loadScrollbar } = useScrollable()

      onMounted(()=> {
        loadScrollbar()
      })

      const pushControls = () => {
        emit('submitControls')
      }

      return { route, showEditSection, pushControls }
    }
  }
</script>