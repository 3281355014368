import { ref } from 'vue'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/automated_apis'
const pluginsControllerUrl = '/plugin_configs'

const modelName = 'Automated API'

export default function useAutomatedApis() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const apisData = ref([])
  const apiPlugins = ref([])

  const getIndex = () => {
    secured.get( controllerUrl ).then(response => {
      response.status === 200 ? apisData.value = response.data.automated_apis : notifyError(modelName, 'Failed to load apis')
    }).catch(error => { notifyError(modelName, error) })
  }

  const getApiPlugins = (companyId, apiId) => {
    secured.get(pluginsControllerUrl, { params: { query_options: {  } } }).then(response => {
      response.status === 200 ? apiPlugins.value = response.data.plugin_configs : notifyError('Plugins', 'Failed to load plugins')
    }).catch(error => { notifyError('Plugins', error) })
  }

  return {
    getIndex, apisData, getApiPlugins, apiPlugins 
  }  
}