<template>
  <li class="dropdown">
    <a href="" class="dropdown-toggle no-after peers fxw-nw ai-c lh-1" data-bs-toggle="dropdown">
      <div class="peer mR-10">
        <i class="ti ti-user"></i>
      </div>
      <div class="peer">
        <span class="fsz-sm c-grey-900">{{ store.getters.loggedInUser.name }}</span>
      </div>
    </a>
    <ul class="dropdown-menu fsz-sm">
      <li>
        <a href="javascript:void(0)" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
          <span>{{ store.getters.currentCompany.name }}</span>
        </a>
      </li>
      <li>
        <a href="" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700" @click.prevent="logout">
          <i class="ti-power-off mR-10"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
  import { reactive } from "vue"
  import useAuth from '@/composables/admin/auth.composable'
  import store from '@/store'

  export default {
    setup() {
      const { logout } = useAuth()
      return {
        logout, store
      }
    }
  }
</script>