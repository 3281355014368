<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Status</th>
        <th scope="col">Vendor</th>
        <th scope="col">Setup Control</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(assessment, idx) in assessments">
        <th scope="row">{{ idx + 1 }}</th>
        <td>{{ assessment.name }}</td>
        <td>{{ toCamel(assessment.status) }}</td>
        <td>{{ assessment.vendor_name }}</td>
        <td>
          <a :href="`assessments/${assessment.id}/edit`" v-if="assessment.status == 'draft'">
            <span class="icon-holder"><i class="c-light-blue-500 ti-settings"></i></span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    props: ['assessments', 'isDraft'],
    setup(props) {
      const toCamel = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      return {
        toCamel
      }
    }
  }
</script>