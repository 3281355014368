<template>
  <div v-if="isLoading" class="overlay">
    <div class="loader"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
};
</script>