import { ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/comments'

export default function useComments() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const comments = ref([])
  const notificationComments = ref([])
  const route = useRoute()

  const getIndex = (payload) => {
    secured.get(controllerUrl, { params: { query_options: payload } } ).then(response => {
      response.status === 200 ? comments.value = response.data.comments.reverse() : notifyError('Comments', 'Failed to load comments')
    }).catch(error => { notifyError('Comments', error) })
  }

  const postComment = (payload) => {
    if (payload.body == '') {
      notifyError('Comments', 'Comment body is required')
    } else {
      secured.post(controllerUrl, { comment: payload }).then(response => {
        response.status === 201 ? comments.value.push(0, 0, response.data) : notifyError('Comments', 'Failed to create comment')
      }).catch(error => { notifyError('Comments', error) })
    }
  }

  const getNotifications = () => {
    secured.get(controllerUrl, { params: { page: 1, per: 5 } } ).then(response => {
      response.status === 200 ? notificationComments.value = response.data.comments : notifyError('Comments', 'Failed to load notifications')
    }).catch(error => { notifyError('Comments', error) })
  }

  return {
    getIndex, postComment, comments, getNotifications, notificationComments
  }  
}