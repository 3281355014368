import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/users/folders'
const docsControllerUrl  = '/documents'

const { notifySuccess, notifyWarn, notifyError } = useNotifier()
const foldersList = ref([])
const folder = ref({})
const assessmentFolderList = ref([])

export default function useFolders() {
  const route = useRoute()
  
  const getFolders = (parentId = '') => {
    if (parentId == '') {
      secured.get(controllerUrl, { params: { query_options: { parent_id: 'null' } } }).then(response => {
        if (response.status === 200) { 
          foldersList.value = response.data.folders
          assessmentFolderList.value =  response.data.assessments
        } else { notifyError('Folders', 'Failed to load folders') }
      }).catch(error => { notifyError('Folders', error) })
    } else {
      secured.get(controllerUrl, { query_options: parentId }).then(response => {
        if (response.status === 200) { foldersList.value = response.data.folders } else { notifyError('Folders', 'Failed to load folders') }
      }).catch(error => { notifyError('Folders', error) })
    }
  }

  const createFolder = (payload) => {
    secured.post(controllerUrl, {folder: payload.value}).then(response => {
      if (response.status == 201) {
        foldersList.value.push(response.data)
        notifySuccess('Folder', 'Folder created successfully')
      }
    }).catch(error => { notifyError('Folder', error) })
  }

  const getFolder = (folderId) => {
    secured.get(controllerUrl + '/' + folderId).then(response => {
      if (response.status === 200) { folder.value = response.data } else { notifyError('Folder', 'Failed to load folder') }
    }).catch(error => { notifyError('Folder', error) })
  }

  const getAssessmentFolder = (assessmentId) => {
    secured.get("/users/assessments/" + assessmentId + '/documents').then(response => {
      if (response.status === 200) { folder.value = response.data } else { notifyError('Folder', 'Failed to load folder') }
    }).catch(error => { notifyError('Folder', error) })
  }

  const uploadAttachment = (payload) => {
    var formData = new FormData()
    formData.append("file", payload)
    return secured.post( docsControllerUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  const updateFolder = (folderData) => {
    return secured.patch( controllerUrl +'/'+ folderData.id, { folder: folderData } )
  }

  return { getFolders, foldersList, assessmentFolderList, createFolder, getFolder, folder, uploadAttachment, updateFolder, getAssessmentFolder }  
}