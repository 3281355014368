<template>
  <div class="email-content open no-inbox-view">
  <div class="email-compose">
    <div class="d-n@md+ p-20"><a class="email-side-toggle c-grey-900 cH-blue-500 td-n" href="javascript:void(0)"><i class="ti-menu"></i></a></div>
    <form class="email-compose-body">
      <h4 class="c-grey-900 mB-20">{{ nodeData.title }}</h4>
      <div class="send-header">
        <div class="mb-3">
          <div class="form-check">
            <label class="form-label form-check-label">
              <input class="form-check-input" type="checkbox" v-model="nodeData.checked"> Show on Report
            </label>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Select Section Type</label> 
            <select id="selectDepartment" class="form-control" v-model="nodeData.section_type" @change="selectChanged">
              <option value="custom">Custom</option>
              <option value="seperator">Seperator</option>
            </select>
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label">Title</label> 
            <input type="text" class="form-control" placeholder="Title" v-model="nodeData.title">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-12">
            <label class="form-label">Body</label>
            <Quill :nodeData="nodeData" />
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-info btn-color" @click="submitNode">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>
<script type="text/javascript">
  import { ref, reactive } from "vue"
  import useReport from '@/composables/admin/reports.composable'
  import Quill from '@/components/common/ReportEditor'

  export default {
    props: ['reportConfig'],
    components: { Quill },
    setup(props, { emit }) {
      const { createNode } = useReport()
      const nodeData = ref({
        title: '',
        body: '',
        checked: true,
        component_name: 'custom_section',
        section_type: 'custom',
        report_configuration_id: props.reportConfig.id
      })

      const selectChanged = () => {
        if (nodeData.value.section_type == 'custom') {
          nodeData.value.component_name = 'custom_section'
        } else {
          nodeData.value.component_name = 'seperator'
        }
      }

      const submitNode = () => {
        createNode(nodeData.value)
      }

      return { nodeData, selectChanged, submitNode }
    }
  }
</script>