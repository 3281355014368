<template>
  <div class="bgc-white p-20 bdL">
    <div class="layer w-100">
      <div class="peers ai-c jc-sb fxw-nw">
        <div class="peer peer-greed">
          <div class="layers">
            <div class="layer w-100"><span class="fw-600 c-grey-600">Control Risk Score</span></div>
            <div class="layer w-100">
              <div class="peers fxw-nw ai-c">
                <div class="peer mR-20">
                  <h3>{{ question.evaluator_response_data.score }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="peer peer-greed">
          <div class="layers ai-fe">
            <div class="layer w-100"><span class="fw-600 c-grey-600">Include On Report</span></div>
            <div class="layer w-100">
              <div class="peers fxw-nw ai-c">
                <div class="peer mR-20">
                  <div class="form-check form-switch">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      role="switch" 
                      :id="`evaluator_response_show_on_report_${question.question_data.id}`"
                      v-model="question.evaluator_response_data.is_shown_on_report"
                      :disabled="responseApproved" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layer w-100 mT-10">
      <div class="mb-3">
        <label class="form-label">Result</label> 
        <select 
          :id="`result_${question.question_data.id}`" 
          class="form-control" 
          v-model="question.evaluator_response_data.option_id" 
          @change="resultChanged" 
          :disabled="responseApproved">
          <option v-for="option in question.question_data.evaluator_options" :value="option.id">{{ option.body}}</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Observations</label> 
        <JustificationEditor 
          :question="question" 
          :onBlur="editingDone"
          v-if="responseApproved == false"
          :disabled="responseApproved"
        />
        <div class="layers bd bgc-white p-20" v-if="responseApproved == true">
          <div class="layer w-100 mB-10" v-html="question.evaluator_response_data.description"> </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Findings</label>
        <FindingsEditor 
          :question="question" 
          :onBlur="editingDone"
          v-if="responseApproved == false"
          :disabled="responseApproved" 
        />
        <div class="layers bd bgc-white p-20" v-if="responseApproved == true">
          <div class="layer w-100 mB-10" v-html="question.evaluator_response_data.findings"> </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="gap-10 peers ai-fe">
          <div class="peer">
            <button 
              type="button" 
              class="btn cur-p bgc-blue-50 c-blue-500" 
              @click="approveRejectControl('approved')"
              :disabled="responseApproved" 
            >{{ question.response_data.status == 'approved' ? 'Approved' : 'Approve' }}
            </button>
          </div>
          <div class="peer">
            <button 
              type="button" 
              class="btn cur-p bgc-red-50 c-red-500" 
              @click="approveRejectControl('reopen')"
              :disabled="responseApproved" 
            >{{ question.response_data.status == 'reopen' ? 'Rejected' : 'Reject' }}</button>
          </div>
          <div class="peer">
            <button 
              type="button" 
              class="btn cur-p bgc-green-50 c-green-500" 
              @click="editingDone"
              :disabled="responseApproved" 
            >Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, computed } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useEvaluatorAssessments from '@/composables/admin/evaluators.assessments.composable'
  import useVendorAssessments from '@/composables/admin/vendors.assessments.composable'
  import useNotifier from '@/composables/common/notify.composable'
  import JustificationEditor from '@/components/common/JustificationEditor'
  import FindingsEditor from '@/components/common/FindingsEditor'
  import store from '@/store'

  export default {
    props: ['question'],
    components: {
      JustificationEditor, FindingsEditor
    },
    setup(props) {
      const question = reactive(props.question)
      const { createEvaluatorResponse, patchEvaluatorResponse, questionResponseValid } = useEvaluatorAssessments()
      const { patchResponse } = useVendorAssessments()
      const { notifySuccess, notifyWarn, notifyError } = useNotifier()
      const editorButtons = ref(['font', '|', 'bold', 'underline', 'italic', 'strikethrough', '|', 'align', 'ul', '|', 'table', 'link', 'undo', 'redo'])
      const disabledPlugins = ref(['addNewLine', 'add-new-line'])
      const plugins = ref([
        { name: 'onBlurEvent', callback: function (editor) { editor.events.on('blur', function () { editingDone() }) } }
      ])

      question.evaluator_response_data.assessment_vendor_response_id = question.response_data.id

      const approveRejectControl = (status) => {
        if (questionResponseValid(question)) {
          question.response_data.status = status
          patchResponse(question.response_data).then(response => {
            response.status === 200 ? question.response_data = response.data : notifyError('Response', 'Failed to approve control')
          }).catch(error => { notifyError('Response', 'Failed to approve control') })
        } else { notifyWarn('Response', 'Please select result') }
      }

      const patchRequest = () => {
        if (questionResponseValid(props.question)) {
          patchEvaluatorResponse(props.question.evaluator_response_data).then(response => {
            response.status === 200 ? props.question.evaluator_response_data = response.data : notifyError('Response', 'Failed to update response')
          }).catch(error => { notifyError('Response', 'Failed to update response') })
        } else { 
          notifyWarn('Response', 'Please select result')
        }
      }

      const createRequest = () => {
        if (questionResponseValid(props.question)) {
          createEvaluatorResponse(props.question.evaluator_response_data).then(response => {
            response.status === 201 ? props.question.evaluator_response_data = response.data : notifyError('Response', 'Failed to create response')
          }).catch(error => { notifyError('Response', 'Failed to create response') })
        } else { notifyWarn('Response', 'Please select result') }
      }

      const resultChanged = () => {
        if (question.evaluator_response_data.option_id > 0) {
          let filteredOption = question.question_data.evaluator_options.filter((item) => {
            return item.id === question.evaluator_response_data.option_id
          })[0]

          if (filteredOption) {
            question.evaluator_response_data.score = filteredOption.score
            question.evaluator_response_data.description = filteredOption.description
            question.evaluator_response_data.findings = filteredOption.findings
          }
          editingDone()
        }
      }

      const editingDone = () => {
        if (question.evaluator_response_data.option_id > 0) {
          props.question.evaluator_response_data.id ? patchRequest() : createRequest()  
        } else {
          notifyError('Response', 'Please select result')
        }
      }

      const responseApproved = computed(() => ['approved', 'reopen'].includes(question.response_data.status))

      return {
        question, plugins, editingDone, approveRejectControl, responseApproved, resultChanged, editorButtons, disabledPlugins
      }
    }
  }
</script>
