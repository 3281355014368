<template>
  <div class="email-app">
    <div class="email-wrapper row remain-height bgc-white ov-h">
      <div class="email-list h-100 layers">
        <SectionHeader :currentIndex="currentSectionIdx" :totalCount="sections.length" @prevClick="prevClicked" @nextClick="nextClicked" v-if="sections.length > 0"/>
        <Sections :sections="sections" v-if="sections.length > 0" v-bind:currentSection="currentSection" @sectionClick="sectionClicked"/>
      </div>
      <div class="email-content h-100" v-if="currentSection.id">
        <div class="layer w-100">
          <div class="peers fxw-nw jc-sb ai-c pY-20 pX-30 bgc-white bdB">
            <div class="peers ai-c">
              <div class="peer">
                <h5 class="lh-1 mB-0" style="color: black;">{{ currentSection.name }} ({{ questionsCompleted }} / {{ currentSection.questions.length }}) questions completed</h5>
              </div>
            </div>
            <div class="gap-10 peers">
              <div class="peer">
                <button type="button" class="btn cur-p bgc-blue-50 c-blue-500" @click="saveSectionResponses" v-show="isLastSection === false" :disabled="buttonDisabled" >
                  Save and Next
                </button>
              </div>
              <div class="peer">
                <button type="button" class="btn cur-p bgc-green-50 c-green-500" @click="submitSectionResponses" v-show="isLastSection === true" :disabled="buttonDisabled">
                  Save and Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <Questions :section="currentSection" :assessment="currentAssessment" :key="`questions_data_${currentSection.id}`"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, computed, watch } from "vue"
  import useVendorAssessments from '@/composables/admin/vendors.assessments.composable'
  import SectionHeader from '@/components/common/SectionHeader'
  import Sections from '@/components/common/Sections'
  import Questions from '@/components/vendors/Questions'
  import useEventsBus from '@/composables/common/eventbus.composable'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'VendorControl',
    components: { SectionHeader, Sections, Questions },
    setup() {
      const { 
        getSections, 
        sections, 
        currentSectionIdx,
        currentSection,
        currentAssessment,
        prevClicked,
        nextClicked, 
        sectionClicked,
        saveSectionResponses,
        submitSectionResponses
      } = useVendorAssessments()

      const { bus } = useEventsBus()

      watch(() => bus.value.get('filterChanged'), (val) => {
        getSections()
      })

      onMounted(()=> {
        getSections()
      })

      const questionsCompleted = computed(() => {
        const attemptedQuestions = currentSection.value.questions.filter(question => {
          return question.response_data.id != null
        }) 
        return attemptedQuestions.length
      })

      const isLastSection = computed(() => {
        return currentSectionIdx.value === sections.value.length - 1
      })

      const buttonDisabled = computed(() => {
        return currentAssessment.value.status === 'vendor_completed'
      })

      return { 
        sections, 
        currentSectionIdx,
        currentSection,
        currentAssessment,
        prevClicked,
        nextClicked, 
        sectionClicked,
        isLastSection,
        buttonDisabled,
        questionsCompleted,
        saveSectionResponses,
        submitSectionResponses
      }
    }
  }
</script>