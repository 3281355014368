<template>
  <component :is="layout">
    <notifications />
    <router-view />
    <Loader></Loader>
  </component>
</template>

<script>
  import Loader from "@/components/ui/Loader"
  const default_layout = "other"
  export default {
    components: { Loader },
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + "-layout"
      }
    }
  }
</script>