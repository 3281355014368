<template>
  <div class="email-wrapper row remain-height bgc-white ov-h">
    <div class="email-list h-100 layers">
      <div class="layer w-100">
        <div class="bgc-white-100 ai-c jc-sb fxw-nw bdB" style="padding: 20px 20px 13px 20px;">
          <div class="peers ai-c jc-sb">
            <div class="peers peer-greed">
              <h4 class="c-grey-900">Sections</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="layer w-100 fxg-1 scrollable pos-r ps" v-if="assessmentData.sections_and_questions">
        <div class="" v-for="(value, key, index) in assessmentData.sections_and_questions['sections']" :class="{selectedColor: key === selectedSection.identifier }">
          <div class="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p" @click="sectionSelected(key)">
            <div class="peer peer-greed ov-h">
              <h5 class="fsz-def tt-c c-grey-900" style="margin-bottom: 0px !important">{{ value.name }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuestionsList :selectedSection="selectedSection" :key="`questions_list_${selectedSection.identifier}`"/>
  </div>
</template>

<style type="text/css">
  .selectedColor {
    background-color: #f8f9fa;
  }
</style>

<script>
  import { ref, onMounted, watch, nextTick } from "vue"
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useAssessments from '@/composables/admin/assessments.composable'
  import useScrollable from '@/composables/common/scroll.composable'
  import useEventsBus from '@/composables/common/eventbus.composable'
  import QuestionsList from '@/components/assessments/QuestionsList'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { QuestionsList },
    setup() {
      const route = useRoute()
      const { getAssessment, assessmentData, updateAssessment } = useAssessments()
      const { loadScrollbar } = useScrollable()
      const selectedSection = ref({})
      const { bus } = useEventsBus()
      const editSection = ref({})
      const showEditSection = ref(false)
      const dataModal = ref(null)

      onMounted(()=> {
        getAssessment(route.params.assessment_id)
      })

      watch(() => bus.value.get('editSectionData'), (val) => {
        editSection.value = val.value
        showEditSection.value = true
        loadScrollbar()
      })

      watch(() => assessmentData.value, (val) => {
        nextTick().then(() => {
          const sectionKeys = Object.keys(assessmentData.value.sections_and_questions['sections'])
          selectedSection.value = assessmentData.value.sections_and_questions['sections'][sectionKeys[0]]
          loadScrollbar()
        })
      })

      watch(() => bus.value.get('submitControls'), (val) => {
        updateAssessment(assessmentData.value)
      })

      const sectionSelected = (key) => {
        selectedSection.value = assessmentData.value.sections_and_questions['sections'][key]
        loadScrollbar()
      }

      return { assessmentData, sectionSelected, selectedSection, route, editSection, showEditSection, dataModal }
    }
  }
</script>