<template>
  <div class="layer w-100">
    <div class="peers fxw-nw jc-sb ai-c pY-20 pX-30 bgc-white bdB">
      <div class="c-grey-600 fsz-sm">
        <div class="btn-group pull-right " role="group">
          <button type="button" class="fsz-xs btn bgc-white bdrs-2 mR-3 cur-p" @click="prevClicked">
            <i class="ti-angle-left"></i>
          </button>
          <button type="button" class="fsz-xs btn bgc-white bdrs-2 mR-3 cur-p" @click="nextClicked">
            <i class="ti-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, reactive } from "vue"
  export default {
    setup(props, { emit }) {
      const prevClicked = () => {
        emit('prevClick')
      }

      const nextClicked = () => {
        emit('nextClick')
      }

      return {
        prevClicked, nextClicked
      }
    }
  }
</script>