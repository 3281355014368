<template>
  <div class="modal fade" id="automated_api_form" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Automated API</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row gap-20">
            <div class="col-md-3" v-for="api in apisData">
              <div class="bgc-light-blue-500 ta-c p-30" @click="loadApiForm(api.id)" data-bs-dismiss="modal">
                <h3 class="c-white">{{ api.name }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  import useAutomatedApis from '@/composables/admin/automatedapi.composable'
  import router from '@/router'

  export default {
    props: ['companyId'],
    setup(props) {
      const { getIndex, apisData } = useAutomatedApis()
      
      onMounted(()=> {
        getIndex()
      })

      const loadApiForm = (apiId) => {
        router.push({name: 'EditPlugin', params: { id: props.companyId, api_id: apiId } })
      }

      return {
        apisData, loadApiForm
      }
    }
  }
</script>