import { createRouter, createWebHistory } from 'vue-router'
import useNotifier from '@/composables/common/notify.composable'
import store from '@/store'

import Login from '@/views/auth/Login'
import SelectRole from '@/views/auth/SelectRole'

import Dashboard from '@/views/Dashboard'

import Company from '@/views/Company'
import CompanyIndex from '@/views/companies/Index'
import CompanyForm from '@/views/companies/Form'
import CompanyPluginForm from '@/views/companies/PluginForm'

import Assessment from '@/views/Assessment'
import AssessmentIndex from '@/views/assessments/Index'
import AssessmentForm from '@/views/assessments/Form'
import SendAssessment from '@/views/assessments/SendAssessment'

import EvaluatorAssessment from '@/views/Evaluator'
import EvaluatorAssessmentIndex from '@/views/evaluators/Index'
import EvaluatorAssessmentControl from '@/views/evaluators/Control'

import VendorAssessment from '@/views/Vendor'
import VendorAssessmentIndex from '@/views/vendors/Index'
import VendorAssessmentControl from '@/views/vendors/Control'

import Folder from '@/views/Folder'
import FolderIndex from '@/views/folders/Index'
import FolderDetail from '@/views/folders/Detail'

import Report from '@/views/Report'
import ReportIndex from '@/views/reports/Index'
import ReportDetail from '@/views/reports/Detail'

import Department from '@/views/Department'
import DepartmentIndex from '@/views/departments/Index'
import DepartmentAddUser from '@/views/departments/AddUser'

const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  VENDOR: "vendor",
  EVALUATOR: "evaluator",
}

const ALLROLES = ['super_admin', 'admin', 'evaluator', 'vendor']

const { notifySuccess, notifyWarn, notifyError } = useNotifier()

const routes = [
  { path: '', name: 'Home', meta: { layout: 'auth', requriesAuth: true, allowedRoles: ALLROLES } },
  { 
    path: '/auth', 
    name: 'Auth', 
    children: [ 
      { path: 'login', component: Login, meta: { layout: 'auth', requriesAuth: false } },
      { path: 'select_role', component: SelectRole, meta: { layout: 'auth', requriesAuth: false } }  
    ] 
  },
  { 
    path: '/evidence_center', 
    component: Folder,
    name: 'Folder', 
    meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
    children: [ 
      { path: '', name: 'FolderIndex', component: FolderIndex, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES }  },
      { path: ':id/detail', name: 'FolderDetail', component: FolderDetail, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES } }
    ] 
  },
  { 
    path: '/roles', 
    component: Department,
    name: 'Department', 
    meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
    children: [ 
      { path: '', name: 'DepartmentIndex', component: DepartmentIndex, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES }  },
      { path: ':id/add_user', name: 'DepartmentAddUser', component: DepartmentAddUser, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES } },
    ] 
  },
  { 
    path: '/reports', 
    component: Report,
    name: 'Report', 
    meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
    children: [ 
      { path: '', name: 'ReportIndex', component: ReportIndex, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES }  },
      { path: ':id/detail', name: 'ReportDetail', component: ReportDetail, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES } }
    ] 
  },
  { 
    path: '/companies', component: Company,
    meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
    children: [
      { 
        path: '', name: 'CompaniesIndex', component: CompanyIndex, meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES } 
      },
      { 
        path: 'new', name: 'NewCompany', component: CompanyForm, meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] } 
      },
      { 
        path: ':id/edit', name: 'EditCompany', component: CompanyForm, meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] } 
      },
      { 
        path: ':id/:api_id/edit', name: 'EditPlugin', component: CompanyPluginForm, meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] } 
      },
      { 
        path: ':id', 
        meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
        children: [
          { 
            path: 'assessments', 
            name: 'Assessments',
            component: Assessment, 
            meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] },
            children: [
              { 
                path: '', 
                name: 'AssessmentsIndex', 
                component: AssessmentIndex, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN] } 
              },
              { 
                path: ':assessment_id/edit', 
                name: 'EditAssessment', 
                component: AssessmentForm, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.EVALUATOR] } 
              },
              { 
                path: ':assessment_id/send_assessment', 
                name: 'SendAssessment', 
                component: SendAssessment, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.EVALUATOR] } 
              }
            ]
          }
        ] 
      },
      { 
        path: ':id', 
        meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
        children: [
          { 
            path: 'evaluators', 
            component: EvaluatorAssessment, 
            meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.EVALUATOR] },
            children: [
              { 
                path: '', 
                name: 'EvaluatorAssessmentsIndex', 
                component: EvaluatorAssessmentIndex, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.EVALUATOR] } 
              },
              { 
                path: ':evaluator_assessment_id/controls', 
                name: 'EvaluatorAssessmentsControl', 
                component: EvaluatorAssessmentControl, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.EVALUATOR] } 
              }
            ]
          }
        ] 
      },
      { 
        path: ':id', 
        meta: { layout: 'other', requriesAuth: true, allowedRoles: ALLROLES },
        children: [
          { 
            path: 'vendors', 
            component: VendorAssessment, 
            meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.VENDOR] },
            children: [
              { 
                path: '', 
                name: 'VendorAssessmentsIndex', 
                component: VendorAssessmentIndex, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.VENDOR] } 
              },
              { 
                path: ':vendor_assessment_id/controls', 
                name: 'VendorAssessmentsControl', 
                component: VendorAssessmentControl, 
                meta: { layout: 'other', requriesAuth: true, allowedRoles: [ROLES.VENDOR] } 
              }
            ]
          }
        ] 
      }
    ] 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    let position = {}
    if (to.hash) {
      position = {
        selector : to.hash
      };
    } else {
      position = {x : 0 , y : 0}
    }
    return position;
  }
})

router.beforeEach(( to, from, next ) => {
  if( to.matched.some( record => record.meta.requriesAuth ) ) {
    const allowedRoles = to.meta.allowedRoles
    if( store.getters.authorizationToken && store.getters.authorizationToken != '' ) {
      if (allowedRoles.includes('any') || allowedRoles.includes(store.getters.selectedRole)) {
        next()
      } else {
        notifyError('ComplyBay', 'You are not authorized to perform this action')
        next({
          path: 'companies'
        })
      }
    } else {
      next({
        path: '/auth/login'
      })
    }
  } else {
    next()
  }
})

export default router
