<template>
  <div class="email-content-wrapper bgc-white p-10" :id="`vendor_response_box_${question.question_data.id}`" :key="`vendor_response_box_${question.question_data.id}`">
    <QuestionDetail 
      :question="question" 
      :index="index" 
      :section="section" 
      :id="`vendor_response_questiondetail_${question.question_data.id}`" 
      :key="`vendor_response_questiondetail_${question.question_data.id}`"
    />
    <div class="peer peer-greed form-check" style="padding-left: 0px !important;" v-if="question.question_data.input_type == 'boolean'">
      <div class="row boxed-check-group boxed-check-info">
        <div class="col-12 col-sm-6 pB-10" v-for="option in question.question_data.options">
          <label class="boxed-check">
            <input 
              class="boxed-check-input" type="radio" 
              :key="`vendor_response_key_${option.id}`" 
              :name="`vendor_response_name_${option.id}`" 
              v-model="question.response_data.option_id" 
              :value="option.id" 
              @change="editingDone" 
              :disabled="isDisabled"
            >
            <div class="boxed-check-label">{{ option.body }}</div>
          </label>
        </div>
      </div>
    </div>
    <div class="mT-10" v-if="store.getters.selectedRole=='vendor'">
      <label class="form-label">Justify your answer below</label> 
      <Quill 
        :question="question" 
        :editingDone="editingDone"
        :disabled="isDisabled"
      />
    </div>
    <div class="mT-10" v-else>
      <label class="form-label">Vendor justification</label> 
      <div class="layers bd bgc-white p-20">
        <div class="layer w-100 mB-10" v-html="question.response_data.justification"> </div>
      </div>
    </div>
    <div class="mT-10">
      <div class="gap-10 peers">
        <div class="peer">
          <button type="button" class="btn cur-p btn-light" data-bs-toggle="modal" :data-bs-target="`#attachments_for_${section.id}_${question.question_data.id}`">
            <i class="ti-clip"></i> 
            {{ question.question_data.attachments_count }} Attachments
          </button>
        </div>
        <div class="peer">
          <button type="button" class="btn cur-p btn-light" data-bs-toggle="modal" :data-bs-target="`#comments_for_${section.id}_${question.question_data.id}`" @click="showComment">
            <i class="ti-comment-alt"></i> 
            {{ question.question_data.comments_count }} Comments
          </button>
        </div>
      </div>
    </div>
    <Attachment :question="question" :section="section" ref="attachmentRef"/>
    <Comment :question="question" :section="section" ref="commentRef"/>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, computed } from "vue"

  import QuestionDetail from '@/components/common/QuestionDetail'
  import Attachment from '@/components/common/Attachment'
  import Comment from '@/components/common/Comment'

  import useVendorAssessments from '@/composables/admin/vendors.assessments.composable'
  import useNotifier from '@/composables/common/notify.composable'

  import Quill from '@/components/common/VendorEditor'

  import store from '@/store'

  export default {
    props: ['question', 'index', 'section', 'assessment'],
    
    components: {
      QuestionDetail, Attachment, Comment, Quill
    },
    
    setup(props, { emit }) {
      const commentRef = ref()
      const attachmentRef = ref()

      const { questionResponseValid, createResponse, patchResponse } = useVendorAssessments()
      const { notifySuccess, notifyWarn, notifyError } = useNotifier()

      const plugins = ref([
        { name: 'onBlurEvent', callback: function (editor) { editor.events.on('blur', function () { editingDone() }) } }
      ])
      const showComment = () => { commentRef.value.loadComments() }

      const patchRequest = () => {
        props.question.response_data.status = 'completed'
        patchResponse(props.question.response_data).then(response => {
          response.status === 200 ? props.question.response_data = response.data : notifyError('Response', 'Failed to update response')
        }).catch(error => { notifyError('Response', 'Failed to update response') })
      }

      const createRequest = () => {
        props.question.response_data.status = 'completed'
        createResponse(props.question.response_data).then(response => {
          response.status === 201 ? props.question.response_data = response.data : notifyError('Response', 'Failed to create response')
        }).catch(error => { notifyError('Response', 'Failed to create response') })
      }

      const editingDone = () => {
        if (questionResponseValid(props.question) == true) {
          props.question.response_data.id ? patchRequest() : createRequest()
        }
      }

      const isDisabled = computed(() => 
        props.question.response_data.status == 'approved' || store.getters.selectedRole == 'evaluator' || (props.assessment.status === 'vendor_completed' && props.question.response_data.status != 'reopen')
      )

      return { editingDone, showComment, commentRef, attachmentRef, isDisabled, plugins, store }
    }
  }
</script>