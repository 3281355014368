<template>
  <div class="modal fade" :id="`info_for_${section.id}_${question.question_data.id}`" :key="`info_for_${section.id}_${question.question_data.id}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Info</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mT-5">
            <div class="col-md-3">
              Control Description:
            </div>
            <div class="col-md-9" v-html="question.question_data.description"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Question:
            </div>
            <div class="col-md-9" v-html="question.question_data.body"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Evidence Description:
            </div>
            <div class="col-md-9" v-html="question.question_data.evidence_description"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Evidence Expected:
            </div>
            <div class="col-md-9" v-html="question.question_data.evidence_expected"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Priority:
            </div>
            <div class="col-md-9" v-html="question.question_data.priority"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Department:
            </div>
            <div class="col-md-9" v-html="question.question_data.department"></div>
          </div>
          <div class="row mT-15">
            <div class="col-md-3">
              Reference Link:
            </div>
            <div class="col-md-9" v-html="question.question_data.reference_link"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  export default {
    props: ['question', 'section'],
    setup(props) {
      const question = reactive(props.question)
      return {
        question
      }
    }
  }
</script>