<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Companies</h4>
          </div>
          <div class="peer" v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == true">
            <a href="companies/new" class="btn cur-p btn-success btn-color"> <span class="icon-holder"><i class="c-white ti-plus"></i></span></a>
          </div>
        </div>
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width: 10%">#</th>
              <th scope="col" style="width: 20%">Name</th>
              <th scope="col" style="width: 40%">Description</th>
              <th scope="col" style="width: 20%">Website</th>
              <th scope="col"  style="width: 10%" v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == true">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(company, idx) in companiesList">
              <th scope="row">{{ idx + 1 }}</th>
              <td><a href="javascript:void(0)" class="link-primary" @click="showAssessments(company)">{{ company.name }}</a></td>
              <td>{{ company.description }}</td>
              <td>{{ company.website }}</td>
              <td v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == true">
                <a :href="`companies/${company.id}/edit`"><span class="icon-holder"><i class="c-light-blue-500 ti-pencil"></i></span></a>&nbsp;&nbsp;
                <a 
                  href="javascript:void(0)" 
                  data-bs-toggle="modal" 
                  data-bs-target="#automated_api_form"
                >
                  <span class="icon-holder"><i class="c-light-blue-500 ti-pulse"></i></span>
                </a>
              </td>
              <AutomatedApiForm :key="`company_api_edit_${company.id}`" :companyId="company.id"/>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, nextTick } from "vue"
  import useCompanies from '@/composables/admin/companies.composable'
  import AutomatedApiForm from '@/components/companies/AutomatedApiForm'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { AutomatedApiForm },
    setup() {
      const { getIndex, companiesList } = useCompanies()

      onMounted(()=> {
        getIndex()
      })

      const showAssessments = (company) => {
        store.commit("setCurrentCompany", company)
        nextTick().then(() => {
          switch (store.getters.selectedRole) {
            case "vendor":
              router.push({name: 'VendorAssessmentsIndex', params: {id: company.id}})
              break
            case "evaluator":
              router.push({name: 'EvaluatorAssessmentsIndex', params: {id: company.id}})
              break
            default:
              router.push({name: 'AssessmentsIndex', params: {id: company.id}})
          }
        })
      }
      return { companiesList, showAssessments, store }
    }
  }
</script>