<template>
  <div class="full-container">
    <div class="email-app">
      <div class="email-wrapper row remain-height bgc-white ov-h">
        <div class="email-list h-100 layers">
          <div class="layer w-100">
            <div class="peers fxw-nw jc-sb ai-c pY-10 pX-20 bgc-white bdB float-end">
              <div class="c-grey-600 fsz-sm peer" v-if="store.getters.selectedRole != 'vendor'">
                <button type="button" class="btn btn-primary btn-color" @click="addSection"><i class="ti ti-plus"></i></button>
              </div>&nbsp;
              <div class="c-grey-600 fsz-sm peer">
                <button type="button" class="btn btn-primary btn-color" @click="showPreview"><i class="ti ti-eye"></i></button>
              </div>
            </div>
          </div>
          <div class="layer w-100 fxg-1 scrollable pos-r ps ps--active-y">
            <div style="overflow: auto;">
              <Tree 
                :value="reportConfig.sections" 
                edgeScroll 
                v-slot="{ node, index, path, tree }" 
                v-if="reportConfig.sections" 
                ref="treeRef"
                :ondragend="droppedData"
                :foldAllAfterMounted="true"
                :maxLevel=1
              >
              <span class="list-group">
                <div 
                  class="list-group-item list-group-item-action" @click="showFields(node)"
                  :style="[node.id == selectedNode.id ? { 'background-color': '#e9ecef' } : '' ]"
                >
                  <div class="row">
                    <div class="col-md-11">
                      <b @click="tree.toggleFold(node, path)" v-if="node?.children?.length > 0">
                        {{node.$folded ? '+' : '-'}}&nbsp;
                      </b> 
                      {{node.title}}
                    </div>
                    <div class="col-md-1">
                      <i class="ti-check-box" v-if="node.checked"></i>
                    </div>      
                  </div>
                </div>
              </span>
              </Tree>
            </div>
           </div>
        </div>
        <div class="email-content h-100 scrollable pos-r ps">
          <div v-for="section in reportConfig.sections" :key="section.id" v-if="showAddSection === false">
            <component :is="componentsList[section.component_name]" :reportConfig="reportConfig" :nodeData="selectedNode" v-if="selectedNode.id === section.id" />
            <div v-for="sub_section in section.children" :key="sub_section.id">
              <component :is="componentsList[sub_section.component_name]" :reportConfig="reportConfig" :nodeData="selectedNode" v-if="selectedNode.id === sub_section.id" />
            </div>
          </div>
          <AddSection :reportConfig="reportConfig" v-if="showAddSection" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, shallowRef, onMounted, reactive, nextTick, watch } from "vue"
  import store from '@/store'
  import { Tree, Fold, Check, Draggable, foldAll, unfoldAll, getPureTreeData } from 'he-tree-vue'
  import 'he-tree-vue/dist/he-tree-vue.css'
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useReport from '@/composables/admin/reports.composable'
  import useScrollable from '@/composables/common/scroll.composable'
  import useEventsBus from '@/composables/common/eventbus.composable'

  import CoverPage from '@/components/reports/CoverPage'
  import AuditorsReport from '@/components/reports/AuditorsReport'
  import CustomSection from '@/components/reports/CustomSection'
  import ManagementAssertion from '@/components/reports/ManagementAssertion'
  import OtherInformation from '@/components/reports/OtherInformation'
  import Seperator from '@/components/reports/Seperator'
  import SystemDescription from '@/components/reports/SystemDescription'
  import TrustServiceCriteria from '@/components/reports/TrustServiceCriteria'
  import Scope from '@/components/reports/Scope'
  import OrgResponsibility from '@/components/reports/OrgResponsibility'
  import AuditorResponsibility from '@/components/reports/AuditorResponsibility'
  import InherentLimitation from '@/components/reports/InherentLimitation'
  import Opinion from '@/components/reports/Opinion'
  import RestrictedUse from '@/components/reports/RestrictedUse'
  import AddSection from '@/components/reports/AddSection'

  export default {
    components: {
      Tree: Tree.mixPlugins([Draggable, Fold, Check]),
      AddSection
    },
    setup() {
      const { getReportConfig, reportConfig, updateNode, reportPreview } = useReport()
      const treeRef = ref()
      const selectedNode = ref({})
      const showAddSection = ref(false)
      const route = useRoute()
      const { loadScrollbar } = useScrollable()
      const { bus } = useEventsBus()

      const componentsList = shallowRef({
        'cover_page': CoverPage,
        'management_assertion': ManagementAssertion,
        'auditors_report': AuditorsReport,
        'system_description': SystemDescription,
        'trust_services_criteria': TrustServiceCriteria,
        'other_information': OtherInformation,
        'custom_section': CustomSection,
        'seperator': Seperator,
        'scope': Scope,
        'org_responsibility': OrgResponsibility,
        'auditors_responsibility': AuditorResponsibility,
        'inherent_limitations': InherentLimitation,
        'opinion': Opinion,
        'restricted_use': RestrictedUse
      })

      onMounted(()=> {
        getReportConfig()
      })
      
      watch(() => bus.value.get('loadFirstNode'), (val) => {
        showFields(val[0])
      })

      const showFields = (node) => {
        selectedNode.value = node
        showAddSection.value = false
        loadScrollbar()
      }

      const droppedData = () => {
        nextTick(() => {
          treeRef.value.treeData.forEach((value, index) => {
            value.section_order = index
            value.parent_id = null
            updateNode(value, false)
            value.children?.forEach((child_value, child_index) => {
              child_value.section_order = child_index
              child_value.parent_id = value.id
              updateNode(child_value, false)
            })
          })
        })
        
      }

      const addSection = () => {
        showAddSection.value = true
      }

      const showPreview = () => {
        reportPreview(route.params.id)
      }

      return { reportConfig, treeRef, showFields, selectedNode, componentsList, droppedData, addSection, showAddSection, route, showPreview , store}
    }

   }
  
</script>

<style>
  .tree-node {
    border: none !important;
    margin-bottom: 0px !important
  }

  
</style>