<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-logo">
        <div class="peers ai-c fxw-nw">
          <div class="peer peer-greed">
            <a class="sidebar-link td-n" href="javascript:void(0)">
              <div class="peers ai-c fxw-nw">
                <div class="peer">
                  <div class="logo">
                    <img src="/assets/logo.svg" alt="" class="centerSidebarLogo">
                  </div>
                </div>
                <div class="peer peer-greed">
                  <h5 class="lh-1 mB-0 logo-text">ComplyBay</h5>
                </div>
              </div>
            </a>
          </div>
          <div class="peer">
            <div class="mobile-toggle sidebar-toggle">
              <a href="" class="td-n">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul class="sidebar-menu scrollable pos-r">
        <div class="mT-30"></div>
        <li class="nav-item">
          <a class="sidebar-link" href="/companies">
            <span class="icon-holder">
              <i class="c-orange-500 ti-layout-list-thumb"></i>
            </span>
            <span class="title">Companies</span>
          </a>
        </li>
        <li class="nav-item" v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == false && store.getters.currentCompany.id">
          <a class="sidebar-link" href="/roles">
            <span class="icon-holder">
              <i class="c-orange-500 ti-server"></i>
            </span>
            <span class="title">Roles</span>
          </a>
        </li>
        <li class="nav-item" v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == false && store.getters.currentCompany.id">
          <a class="sidebar-link" href="/reports">
            <span class="icon-holder">
              <i class="c-teal-500 ti-stats-up"></i>
            </span>
            <span class="title">Reports</span>
          </a>
        </li>
        <li class="nav-item" v-if="['super_admin', 'admin'].includes(store.getters.selectedRole) == false && store.getters.currentCompany.id">
          <a class="sidebar-link" href="/evidence_center">
            <span class="icon-holder">
              <i class="c-purple-500 ti-folder"></i>
            </span>
            <span class="title">Evidence Center</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue"
  import { useRoute } from 'vue-router'
  import store from '@/store'

  export default {
    name: 'Sidebar',
    setup() {
      const route = useRoute()
      const sidebarItems = ref({
        'super_admin': [
          { title: 'Companies', path: '/companies', iconClass: 'c-orange-500 ti-layout-list-thumb' },
        ],

        'admin': [
          { title: 'Companies', path: '/companies', iconClass: 'c-orange-500 ti-layout-list-thumb' },
        ],

        'evaluator': [
          { title: 'Companies', path: '/companies', iconClass: 'c-orange-500 ti-layout-list-thumb' },
          { title: 'Departments', path: '/departments', iconClass: 'c-orange-500 ti-server' },
          { title: 'Reports', path: '/reports', iconClass: 'c-teal-500 ti-stats-up' },
          { title: 'Folders', path: '/folders', iconClass: 'c-purple-500 ti-folder' },
          { title: 'Policy', path: '/policies', iconClass: 'c-pink-500 ti-briefcase' }
        ],

        'vendor': [
          { title: 'Companies', path: '/companies', iconClass: 'c-orange-500 ti-layout-list-thumb' },
          { title: 'Departments', path: '/departments', iconClass: 'c-orange-500 ti-server' },
          { title: 'Reports', path: '/reports', iconClass: 'c-light-blue-500 ti-stats-up' },
          { title: 'Folders', path: 'folders', iconClass: 'c-orange-500 ti-folder' },
          { title: 'Policy', path: '/policies', iconClass: 'c-teal-500 ti-briefcase' }
        ]
      })

      return {
        store, sidebarItems
      }
    }
  }
</script>