<template>
  <div class="row">
    <div class="mb-3 col-md-4">
      <label class="form-label">Name</label>
      <input type="text" class="form-control" placeholder="Name" v-model="user.name">
    </div>
    <div class="mb-3 col-md-4">
      <label class="form-label">Email</label>
      <input type="email" class="form-control" placeholder="Email" v-model="user.email">
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  export default {
    props: ['user'],
    setup() {

    }
  }
</script>