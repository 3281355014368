<template>
	<div class="modal fade" 
    :id="`comments_for_${section.id}_${question.question_data.id}`" 
    tabindex="-1" :aria-labelledby="`attachments_for_${section.id}_${question.question_data.id}`" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <small>Comments</small>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="peers fxw-nw pos-r">
            <div class="peer peer-greed" id="chat-box-popup">
              <div class="layers h-100">
                <div class="layer w-100 fxg-1 bgc-grey-200 scrollable pos-r ps">
                  <div class="peers fxw-nw" v-for="comment in comments" :key="`comment_${comment.id}`">
                    <div class="layers ai-fs gapY-5 p-10" v-if="comment.body">
                      <div class="layer">
                        <div class="peers fxw-nw ai-c pY-3 pX-10 bgc-white bdrs-2 lh-3/2">
                          <div class="peer mR-10"><small>{{ comment.created_at }}</small></div>
                          <div class="peer-greed"><span>{{ comment.body }}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="layer w-100">
                  <div class="p-20 bdT bgc-white">
                    <div class="pos-r">
                      <input type="text" class="form-control bdrs-10em m-0" placeholder="Say something..." v-model="payload.body" :id="`comment_${question.question_data.id}`"> 
                      <button type="button" class="btn btn-primary bdrs-50p w-2r p-0 h-2r pos-a r-1 t-1 btn-color" @click="submitComment">
                        <i class="fa fa-paper-plane-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cur-p btn-primary btn-color" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, reactive, defineExpose } from "vue"
  import store from '@/store'
  import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
  import useScrollable from '@/composables/common/scroll.composable'
  import useComments from '@/composables/admin/comment.composable'

  export default {
    props: ['question', 'section'],
    setup(props, context) {
      const route = useRoute()
      const { loadScrollbar } = useScrollable()
      const { getIndex, postComment, comments } = useComments()
      const question = ref(props.question)

      const payload = ref({
        body: '', 
        commentable_id: question.value.question_data.id,
        commentable_type: 'Question',
        user_id: store.getters.loggedInUser.id
      })

      const loadComments = () => {
        getIndex({commentable_id: question.value.question_data.id})
        loadScrollbar()
      }

      function submitComment() {
        postComment(payload.value)
        payload.value.body = ''
      }

      context.expose({ loadComments })

      return {
        route, comments, payload, submitComment, store
      }
    }
  }
</script>