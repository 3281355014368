import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

import { secured, plain } from '@/composables/common/axios.composable'
import useNotifier from '@/composables/common/notify.composable'

const controllerUrl  = '/assessment_evaluators'
const modelName = 'Evaluator Assessments'

export default function useEvaluatorAssessments() {
  const { notifySuccess, notifyWarn, notifyError } = useNotifier()
  const route = useRoute()
  const assessments = ref([])
  const sections = ref([])
  const currentSectionIdx = ref(-1)
  const currentSection = ref({})
  const currentAssessment = ref({})

  const filteredFilters = () => {
    const result = {}
    for (const key in store.getters.filters) {
      if (store.getters.filters[key] !== null && store.getters.filters[key] !== undefined && store.getters.filters[key] !== '') {
          result[key] = store.getters.filters[key];
      }
    }
    return result
  }

  const getIndex = () => {
    secured.get(controllerUrl).then(response => {
      if (response.status === 200) {
        assessments.value = response.data
      } else { notifyError(modelName, 'Failed to load assessments') }
    }).catch(error => { notifyError(modelName, error) })
  }

  const getSections = () => {
    const apiUrl = `${controllerUrl}/${route.params.evaluator_assessment_id}/assessment_wise_sections`
    var apiQuery = secured.get(apiUrl)
    if (store.getters.filters.department != '' || store.getters.filters.priority != '') {
      apiQuery = secured.get(apiUrl, {params: {search_query: filteredFilters()}})
    }
    apiQuery.then(response => {
      if (response.status === 200) {
        currentAssessment.value = response.data.assessment
        sections.value = response.data.sections
        if (sections.value.length > 0) {
          currentSectionIdx.value = 0
          currentSection.value = sections.value[0]  
        }
        store.commit('setFiltersData', {
          departments: currentAssessment.value.departments,
          priorities: currentAssessment.value.priorities,
          statuses: currentAssessment.value.statuses
        })
      } else { 
        notifyError(modelName, 'Failed to load sections')
      }
    }).catch(error => {
      notifyError(modelName, error)
    })
  }

  const prevClicked = () => {
    if (currentSectionIdx.value > 0) {
      currentSectionIdx.value = currentSectionIdx.value - 1
      currentSection.value = sections.value[currentSectionIdx.value]
    }
  }

  const nextClicked = () => {
    if (currentSectionIdx.value < sections.value.length - 1) {
      currentSectionIdx.value = currentSectionIdx.value + 1
      currentSection.value = sections.value[currentSectionIdx.value]
    }
  }

  const updateCurrentIndex = (idx) => {
    if (idx.value >= 0) {
      currentSectionIdx.value = idx.value
      currentSection.value = sections.value[idx.value]
    }
  }

  const sectionClicked = (section, idx) => {
    currentSectionIdx.value = idx
    currentSection.value = section
  }

  const questionResponseValid = (question) => {
    if (question.response_data.option_id > 0) { return true }
    return false
  }

  const sectionResponsesValid = (section) => {
    let validCount = 0
    section.questions.forEach((question, index) => { if (questionResponseValid(question) == true) { validCount += 1 } })
    return validCount === section.questions.length
  }

  const createEvaluatorResponse = (payload) => {
    return secured.post(`${controllerUrl}/${payload.assessment_evaluator_id}/assessment_evaluator_responses`, { assessment_evaluator_response: payload })
  }

  const patchEvaluatorResponse = (payload) => {
    return secured.patch(`${controllerUrl}/${payload.assessment_evaluator_id}/assessment_evaluator_responses/${payload.id}`, { assessment_evaluator_response: payload })
  }

  const saveSectionResponses = () => {
    if (sectionResponsesValid(currentSection.value) == true) {
      currentSection.value.questions.forEach((question, index) => {
        if ( question.response_data.id ) {
          patchEvaluatorResponse(question.evaluator_response_data).then(response => {
            response.status === 200 ? question.evaluator_response_data = response.data : notifyError('Response', 'Failed to update response')
          }).catch(error => { notifyError('Response', 'Failed to update response') })
        }
      })
      if (currentSectionIdx.value < sections.value.length - 1) {
        currentSectionIdx.value = currentSectionIdx.value + 1
        sectionClicked(sections.value[currentSectionIdx.value], currentSectionIdx.value)
      }
    } else {
      notifyError(modelName, 'Please check if all questions are responded')
    }
  }

  const submitSectionResponses = () => {
    let completedSections = 0
    sections.value.forEach((section, index) => {
      if (sectionResponsesValid(section) == true) { completedSections += 1 }
    })
    if (completedSections == sections.value.length) {
      secured.put(`companies/${currentAssessment.value.company_id}/assessments/${currentAssessment.value.id}`, { assessment: { status: 'evaluator_approved' } }).then(response => {
        if (response.status === 200) { 
          notifySuccess(modelName, 'Assessment approved successfully')
          router.push({name: 'EvaluatorAssessmentsIndex', params: {id: store.getters.currentCompany.id}}) 
        } else { 
          notifyError(modelName, 'Failed to approve assessment') 
        }
      }).catch(error => { notifyError(modelName, error) })
    } else {
      notifyError(modelName, 'Please check if all questions in all sections are responded')
    }
  }

  const sendReminder = (assessmentId) => {
    secured.get(controllerUrl + '/assessments/' + assessmentId + '/send_reminder_to_vendors').then(response => {
      notifySuccess('Reminders', 'Reminder sent to vendors successfully')
    }).catch(error => {
      notifyError('Reminders', error)
    })
  }

  const replicateAssessment = (assessmentId, replicateWithUserData) => {
    secured.post(`${controllerUrl}/assessments/replicate_assessment`, { 
      assessment_id: assessmentId, 
      replicate_to_company_id: store.getters.currentCompany.id,
      is_user_data_replicate: replicateWithUserData
    })
  }

  return {
    getIndex, 
    assessments, 
    currentAssessment,
    getSections,
    sections,
    currentSectionIdx,
    currentSection,
    currentAssessment,
    prevClicked,
    nextClicked, 
    sectionClicked,
    questionResponseValid,
    createEvaluatorResponse,
    patchEvaluatorResponse,
    saveSectionResponses,
    submitSectionResponses,
    sendReminder,
    replicateAssessment
  }  
}