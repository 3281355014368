<template>
  <div class="email-content open no-inbox-view">
  <div class="email-compose">
    <div class="d-n@md+ p-20"><a class="email-side-toggle c-grey-900 cH-blue-500 td-n" href="javascript:void(0)"><i class="ti-menu"></i></a></div>
    <form class="email-compose-body">
      <h4 class="c-grey-900 mB-20">{{ nodeData.title }}</h4>
      <div class="send-header">
        <div class="mb-3">
          <div class="form-check">
            <label class="form-label form-check-label">
              <input class="form-check-input" type="checkbox" v-model="nodeData.checked"> Show on Report
            </label>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-12">
            <label class="form-label">Title</label> 
            <input type="text" class="form-control" placeholder="nodeData.title" v-model="nodeData.title">
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-12">
            <label class="form-label">Body</label>
            <Quill :nodeData="nodeData" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-info btn-color" v-on:click="$emit('updateNode', nodeData)">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>
<script type="text/javascript">
  import { ref, reactive } from "vue"
  import Quill from '@/components/common/ReportEditor'

  export default {
    props: ['nodeData', 'reportConfig'],
    components: { Quill },
    setup(props, { emit }) {
      return {  }
    }
  }
</script>