<template>
  <div>
    <Sidebar></Sidebar>
    <div class="page-container">
      <Header></Header>
      <main class="main-content bgc-white-100">
        <div id="mainContent">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import '@/assets/styles/index.scss'
  import '@/assets/scripts/index.js'
  import { reactive } from "vue"
  import store from '@/store'
  import Header from '@/components/ui/Header'
  import Sidebar from '@/components/ui/Sidebar'
  
  export default {
    components: {
      Header, Sidebar
    },
    setup() {
      
    }
  }
</script>