<template>
  <div class="peer pX-40 pY-80 bgc-white scrollable pos-r ps" style="min-width:400px">
    <div id="logoDiv" class="mB-50">
      <img src="/assets/logo.svg" style="margin: 0 auto;">
    </div>
    <div class="mb-3">
      <div class="dropdown container">
        <button class="btn btn-primary btn-color dropdown-toggle center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Role</button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">
          <a class="dropdown-item" href="javascript:void(0)" @click="roleSelected(role)" v-for="role in store.getters.loggedInUser.roles">{{ toCamel(role) }}</a> 
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
  .container {
    height: 50px;
    position: relative;
  }

  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
</style>

<script>
  import { ref } from "vue"
  import store from '@/store'
  
  export default {
    setup() {

      const roleSelected = (role) => {
        store.dispatch('roleSelected', role)
      }
      
      const toCamel = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      return {
        roleSelected, store, toCamel
      }
    }
  }
</script>