<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white p-20 mB-20">
        <div class="peers ai-c jc-sb mB-20">
          <div class="peers peer-greed">
            <h4 class="c-grey-900">Vendor Assessments</h4>
          </div>
        </div>
        <AssessmentList :assessments="filteredAssessments" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, computed } from "vue"
  import useVendorAssessments from '@/composables/admin/vendors.assessments.composable'
  import AssessmentList from '@/components/vendors/Index'

  import store from '@/store'
  import router from '@/router'

  export default {
    name: 'Company',
    components: { AssessmentList },
    setup() {
      const { getIndex, assessments } = useVendorAssessments()

      onMounted(()=> {
        getIndex()
      })

      const filteredAssessments = computed(() => 
        assessments.value.filter((e) => e.assessment.status != 'draft')
      )

      return { filteredAssessments }
    }
  }
</script>