<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Assessment Name</th>
        <th scope="col">Vendor Name</th>
        <th scope="col">Deadline</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(assessment, idx) in assessments">
        <th scope="row">{{ idx + 1 }}</th>
        <td v-if="assessment.assessment.status != 'draft'">
          <a href="javascript:void(0)" class="link-primary" @click="loadAssessment(assessment)">{{ assessment.assessment.name }}</a>
        </td>
        <td v-else>
          {{ assessment.assessment.name }}
        </td>
        <td>{{ assessment.assessment.vendor_name }}</td>
        <td>{{ assessment.assessment.deadline}}</td>
        <td>{{ toCamel(assessment.assessment.status) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import router from '@/router'
  export default {
    props: ['assessments'],
    setup(props) {
      const toCamel = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      const loadAssessment = (assessment) => {
        router.push({name: 'VendorAssessmentsControl', params: {vendor_assessment_id: assessment.id}})
      }

      return {
        toCamel, loadAssessment
      }
    }
  }
</script>