<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Assessment Name</th>
        <th scope="col">Vendor Name</th>
        <th scope="col">Deadline</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(assessment, idx) in assessments">
        <th scope="row">{{ idx + 1 }}</th>
        <td v-if="['in_progress', 'vendor_completed'].includes(assessment.assessment.status)">
          <a href="javascript:void(0)" class="link-primary" @click="loadAssessment(assessment)">{{ assessment.assessment.name }}</a>
        </td>
        <td v-else>
          {{ assessment.assessment.name }}
        </td>
        <td>{{ assessment.assessment.vendor_name }}</td>
        <td>{{ assessment.assessment.deadline}}</td>
        <td>{{ toCamel(assessment.assessment.status) }}</td>
        <td>
          <div class="gap-10 peers">
            <div class="peer" v-if="assessment.assessment.status == 'draft'">
              <a :href="`assessments/${assessment.assessment.id}/edit`" class="btn">
                <span class="icon-holder"><i class="c-light-blue-500 ti-settings"></i></span>
              </a>
            </div>
            <div class="peer" v-if="assessment.assessment.status == 'vendor_completed'">
              <button href="javascript:void(0)" class="btn" data-bs-toggle="modal" :data-bs-target="`#replicate_option_for_${assessment.id}`">
                <i class="c-light-blue-500 ti-files" aria-hidden="true"></i>
              </button>
            </div>
            <div class="peer">
              <button href="javascript:void(0)" class="btn" @click="sendReminderToVendors(assessment.assessment.id)">
                <i class="c-light-blue-500 ti-bell" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </td>
        <ReplicateOption :replicateUserData="replicateUserData" :assessment="assessment" />
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { ref, onMounted, reactive } from "vue"
  import router from '@/router'
  import useEvaluatorAssessments from '@/composables/admin/evaluators.assessments.composable'
  import ReplicateOption from '@/components/common/ReplicateOption'

  export default {
    props: ['assessments'],
    components: { ReplicateOption },
    setup(props) {

      const { sendReminder, replicateAssessment } = useEvaluatorAssessments()
      const replicateUserData = ref(true)

      const toCamel = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, x => x.toUpperCase())
      }

      const loadAssessment = (assessment) => {
        router.push({name: 'EvaluatorAssessmentsControl', params: {evaluator_assessment_id: assessment.id}})
      }

      const sendReminderToVendors = (assessmentId) => {
        sendReminder(assessmentId)
      }

      const replicateAssessmentToCompany = (assessmentId) => {
        if(confirm('Are you sure to replicate this assessment?')) {
          replicateAssessment(assessmentId)
        }
      }

      return {
        toCamel, loadAssessment, sendReminderToVendors, replicateAssessmentToCompany, replicateUserData
      }
    }
  }
</script>