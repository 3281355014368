<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 300px; overflow-y: hidden;"
      v-model="question.evaluator_response_data.description"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
      :id="`evaluator_response_description_${question.question_data.id}`"
      :key="`evaluator_response_description_${question.question_data.id}`"
    />
  </div>
</template>

<script type="text/javascript">
  import '@wangeditor/editor/dist/css/style.css' // import css
  import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { i18nChangeLanguage } from '@wangeditor/editor'
  export default {
    props: ['question'],
    components: { Editor, Toolbar },
    setup(props) {
      const editorRef = ref()

      onMounted(() => {
        i18nChangeLanguage('en')
      })

      const toolbarConfig = { excludeKeys: [
        'fontFamily', 
        'lineHeight', 
        'fullScreen', 
        'group-video', 'insertVideo', 'codeBlock', 'undo', 'redo', 'insertLink', 'todo',
        'group-image', 
        'blockquote',
        'header1', 'header2', 'header3', 'color', 'bgColor', 'clearStyle', 'through'
      ] }
      const editorConfig = { placeholder: 'Type here...' }

      onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
      })

      const handleCreated = (editor) => { editorRef.value = editor }

      return { editorRef, mode: 'simple', toolbarConfig, editorConfig, handleCreated }
    }
  }
</script>
