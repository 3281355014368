<template>
  <div class="peer pX-40 pY-80 bgc-white scrollable pos-r ps" style="min-width:400px">
    <div id="logoDiv" class="mB-50">
      <img src="/assets/logo.svg" style="margin: 0 auto;">
    </div>
    <form @submit.prevent="onSubmit()">
      <div class="mb-3">
        <label class="text-normal text-dark form-label">Email</label> 
        <input type="email" class="form-control" v-model="payload.email" placeholder="John Doe">
      </div>
      <div class="mb-3">
        <label class="text-normal text-dark form-label">Password</label> 
        <input type="password" class="form-control" v-model="payload.password" placeholder="Password">
      </div>
      <div class="peers ai-c jc-sb fxw-nw">
        <div class="peer">
          <div class="peers ai-c">
            <label for="inputCall1" class="peers peer-greed js-sb ai-c form-label">
              <span class="peer peer-greed">Forgot Password?</span>
            </label>
          </div>
        </div>
        <div class="peer">
          <button class="btn btn-primary btn-color">Login</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { reactive } from "vue"
  import store from '@/store'
  
  export default {
    setup() {
      const payload = reactive({ email: "", password: ""})

      function onSubmit() {
        store.dispatch('logIn', payload)
      }
      
      return {
        payload, onSubmit
      }
    }
  }
</script>