<template>
  <div class="header navbar">
    <div class="header-container">
      <Toggle />
      <ul class="nav-right">
        <Notification v-if="['VendorAssessmentsControl', 'EvaluatorAssessmentsControl'].includes(route.name)"/>
        <Settings />
      </ul>
    </div>
  </div>
</template>

<script>
  import Toggle from '@/components/ui/Toggle'
  import Notification from '@/components/ui/Notification'
  import Settings from '@/components/ui/Setting'
  import { useRouter, useRoute } from 'vue-router'

  export default {
    name: 'Header',
    components: {
      Toggle, Notification, Settings
    },
    setup() {
      const route = useRoute()

      return { route }
    }
  }
</script>