<template>
  <div class="p-10" id="chat-box" :key="`questions_list_${section.id}`">
    <div class="bgc-white bd mB-10 p-10" v-for="(question, idx) in section.questions" :key="`question_${question.id}`">
      <div class="row" :class="{ 'mB-20': idx == section.questions.length - 1 }">
        <div class="col-md-7">
          <QuestionForm :question="question" :section="section" :assessment="assessment" :index="idx + 1" :key="`edit_question_${question.id}`"/>
        </div>
        <div class="col-md-5">
          <ScoreForm :question="question" :key="question.question_data.id"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, watch } from "vue"
  import QuestionForm from "@/components/common/QuestionForm"
  import ScoreForm from "@/components/common/ScoreForm"
  import useEventsBus from '@/composables/common/eventbus.composable'

  export default {
    name: 'Header',
    props: ['section', 'assessment'],
    components: {
      QuestionForm, ScoreForm
    },
    setup() {
      
    }
  }
</script>